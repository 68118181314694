/** start compliance **/
.complianceSec{
  background: linear-gradient(180deg,#fef7e2,#eeeefd);
  padding: 30px 0px 30px 0px;
}


.complianceSec .titleSec.mb_50{
  margin-bottom: 25px;
}

.complianceSec .category-list li a {
  text-align: left;
}

.complianceSec .side1 .cmpSidebar {
  padding: 0;
  perspective: 5000px;
  transform: rotateY(4deg);
  transform-style: preserve-3d;
  background: linear-gradient(180deg,#eaf8ff,#f6f6ff);
  transition: all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s;
  border: 1px solid #eaf8ff;
  border-radius: 10px;
  border-top-left-radius: 4%;
  position: relative;
  display: block;
}
/* .complianceSec .side1 .cmpSidebar:hover {
  transform: perspective(1000px) rotateX(5deg) rotateY(2deg) scale3d(1, 1, 1);
} */
.complianceSec .side1 .cmpSidebar .widget-title {
  padding: 10px 0;
  background: transparent;
  position: relative;
display: block;
}
.complianceSec .side1 .cmpSidebar .widget-title h3 {
  background: linear-gradient(to right, #ff8a00, #e52e71);
    background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.complianceSec .side1 .cmpSidebar .widget-content {
  padding: 15px;
}
.complianceSec .complianceBox {
  position: relative;
  padding: 12px 36px;
  z-index: 2;
  height: auto;
}
.complianceSec .cTabs {
  border: 5px solid red
}
.complianceSec .ul.nav-pills .li {
  position: relative;
  display: block;
  margin-bottom: 12px;
}
.complianceSec .ul.nav-pills .li a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #101A30;
  padding: 6px 15px;
  border-radius: 5px;
  text-align: left;
  border: 1px dotted #101A30;
  background-color: inherit;
}
.complianceSec .ul.nav-pills .li a:hover {
  color: #101A30;
  background: linear-gradient(180deg,#eaf8ff,#f6f6ff);
}
.complianceSec .ul.nav-pills .li a span {
  position: relative;
  transition: all 500ms ease;
}
.complianceSec .ul.nav-pills .li a span::before {
  position: absolute;
  content: '\e928';
  font-family: 'icomoon';
  font-size: 12px;
  font-weight: 400;
  opacity: 0;
  left: 0px;
  top: 0px;
  color: var(--title-color);
  transition: all 500ms ease;
}
.complianceSec .ul.nav-pills .li a:hover span {
  padding-left: 24px;
}
.complianceSec .ul.nav-pills .li a:hover span::before {
  opacity: 1;
}

.complianceSec .pt_60.text-center {
  padding-top: 30px;
}

.complianceSec .theme-btn.btn-two span {
  padding: 10px 50px;
  font-weight: bold;
}