/** start industriesSec **/
.industriesSec {
    background: #fafafb;
    padding: 50px 0;
  }
  .industriesSec .titleSec.mb_50 {
    margin-bottom: 0;
  }
  
  .industriesSec .industries-block-one {
    height: 185px;
    width: auto;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.8em;
    text-align: left;
    cursor: pointer;
    margin-bottom: 25px;
  }
  
  /* .industriesSec .industries-block-one::before {
    width: 92%;
    height: 92%;
    left: -20px;
    bottom: -20px;
    background: rgba(0, 2, 72, .1);
    content: "";
    position: absolute;
    z-index: -1;
    transform-style: preserve-3d;
    transform: rotateY(-10deg);
    -webkit-transform: rotateY(-10deg);
    filter: blur(50px);
    -webkit-filter: blur(50px);
    transition: .3s;
  }
  
  .industriesSec .industries-block-one::after {
    background: #f8f8ff;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: perspective(500px) rotateY(-5deg);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 12px;
  }
   */
  
   .industriesSec .titleSec {
    text-align: left;
  }
  
  .industriesSec .titleSec h2.title {
    font-size: 35px;
    line-height: 18px;
    font-weight: 900;
    font-family: DM Sans, sans-serif;
    color: #000248;
    letter-spacing: 1px;
    padding-top: 0em;
  }
  
  .titleSec .sub-title {
    border-radius: 4px;
    height: 36px;
    padding: 0 12px;
    font-size: 15px;
    line-height: 35px;
    display: inline-block;
    margin-bottom: 15px;
  }
  
  .industriesSec .titleSec p {
    font-size: 17px;
    line-height: 30px;
    color: #757589;
    padding-top: 1em;
  }
  
  .industriesSec .industries-block-one h5{
    line-height: 12px;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 700;
    font-family: DM Sans, sans-serif;
    color: #000248;
    text-align: start;
    line-height: 20px;
  }
  
  .industriesSec .industries-block-one p {
    font-size: 15px;
    line-height: 19px;
    color: #757589;
    text-align: start;
  }
  .industriesSec .iconArea img{
    width:55px;
    margin-bottom: 8px;
  }
  
  .theme-button {
    transition: all .5s;
    position: relative;
    color: #000248;
    display: inline-block;
    height: 46px;
    line-height: 46px;
    text-decoration: none;
    padding: 0 25px;
    z-index: 2;
    font-size: 16px;
    border: 0;
    margin-top: 2em;
  }
  
  .theme-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 1px solid #000248;
    transition: all .3s;
  }
  
  .theme-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    background-color: #000248;
    transition: all .3s;
    transform: scale(.5);
  }
  
  .theme-button:hover span {
    color: #fff;
  }
  
  .theme-button:hover::after {
    opacity: 1;
    transform: scale(1);
  }
  
  /** end industriesSec **/