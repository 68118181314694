.default-sidebar .sidebar-widget,
.sidebar-widget2{
  position: relative;
  display: block;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,.05);
background: radial-gradient(100% 100% at 50% 0,#fff 0,hsla(0,0%,100%,0) 100%),linear-gradient(103.94deg,transparent 7.66%,rgba(0,0,0,.015) 32.57%,rgba(0,0,0,.04) 72.45%,transparent 96.25%);
padding: 0;
}

.default-sidebar .sidebar-widget:last-child{
  margin-bottom: 0px;
}

.default-sidebar .category-widget .category-list li{
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.default-sidebar .category-widget .category-list li:last-child{
  margin-bottom: 0px;
}

.default-sidebar .category-widget .category-list li a,
.auction .default-sidebar .category-widget .category-list li{
  background-color: rgb(255, 255, 255);
position: relative;
display: block;
font-size: 16px;
line-height: 28px;
font-weight: 500;
color: #101A30;
background-color: #f6f6f6;
padding: 6px 15px;
border-radius: 5px;
text-align: left;
border: 1px solid #f6f6f6;
}

.default-sidebar .category-widget .category-list li a.current,
.default-sidebar .category-widget .category-list li a:hover{
  color: var(--title-color);
border: 1px dotted var(--title-color);
background: inherit !important;
}

.default-sidebar .category-widget .category-list li a span{
  position: relative;
  transition: all 500ms ease;
}

.default-sidebar .category-widget .category-list li a span:before{
  position: absolute;
  content: '\e928';
  font-family: 'icomoon';
  font-size: 12px;
  font-weight: 400;
  opacity: 0;
  left: 0px;
  top: -4px;
  color: var(--title-color);
  transition: all 500ms ease;
}

.default-sidebar .category-widget .category-list li a.current span,
.default-sidebar .category-widget .category-list li a:hover span{
  padding-left: 24px;
}

.default-sidebar .category-widget .category-list li a.current span:before,
.default-sidebar .category-widget .category-list li a:hover span:before{
  opacity: 1;
}

.default-sidebar .widget-title,
.widget-title{
  position: relative;
  display: block;
  padding: 20px 0;
background-color: #fafafa;
}
.default-sidebar .widget-content{
  padding: 15px;
}

.default-sidebar .widget-title h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}


.default-sidebar .contact-widget{
  position: relative;
  display: block;
  background: #003121;
  box-shadow: 0px 20px 60px rgba(62, 84, 141, 0.1);
  border-radius: 10px;
  padding: 80px 30px 30px 30px;
}

.default-sidebar .contact-widget .icon-box{
  position: relative;
  display: inline-block;
  font-size: 80px;
  color: var(--theme-color);
  margin-bottom: 43px;
}

.default-sidebar .contact-widget .icon-box:before{
  position: absolute;
  content: '';
  width: 100px;
  height: 100px;
  left: -17px;
  top: -20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.default-sidebar .contact-widget h3{
  display: block;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 44px;
}

.default-sidebar .contact-widget .lower-box{
  position: relative;
  display: block;
  padding: 36px 10px;
  border: 1.5px dashed rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.default-sidebar .contact-widget .lower-box p{
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.default-sidebar .contact-widget .lower-box p a{
  display: inline-block;
  color: #fff;
}

.default-sidebar .contact-widget .lower-box p a:hover{
  text-decoration: underline;
}

.default-sidebar .contact-widget .lower-box h2{
  display: block;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.default-sidebar .contact-widget .lower-box h2 a{
  display: inline-block;
  color: #fff;
}

.default-sidebar .contact-widget .lower-box h2 a:hover{
  text-decoration: underline;
}


/** blog-sidebar **/



.blog-sidebar .search-widget .search-form .form-group{
  position: relative;
  margin: 0px;
}

.blog-sidebar .search-widget .search-form .form-group input[type='search']{
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #fff;
  border: 1px solid var(--theme-color);
  border-radius: 5px;
  font-size: 16px;
  color: #222;
  padding: 10px 60px 10px 30px;
  transition: all 500ms ease;
}

.blog-sidebar .search-widget .search-form .form-group button[type='submit']{
  position: absolute;
  top: 19px;
  right: 20px;
  font-size: 18px;
  color: #222;
  cursor: pointer;
  transition: all 500ms ease;
}

.blog-sidebar .search-widget .search-form .form-group input:focus{
  border-color: var(--theme-color);
}

.blog-sidebar .search-widget .search-form .form-group input:focus + button,
.blog-sidebar .search-widget .search-form .form-group button{
  color: var(--theme-color);
}

.blog-sidebar .sidebar-widget{
  margin-bottom: 30px;
}

.blog-sidebar .sidebar-widget:last-child{
  margin-bottom: 0px;
}

