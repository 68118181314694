.team-details{
    position: relative;
  }
  
  .team-details-content{
    position: relative;
    display: block;
    padding: 20px 0;
  }
  .team-details-content hr{
    margin: 1.5rem 0;
  }
  
  .team-details-content .image-box{
    position: relative;
    display: block;
    border-radius: 10px;
    width:100%;
    height:70vh;
  }
  
  .team-details-content .image-box img{
    width: 100%;
    height:100%;
    border-radius: 10px;
    object-fit: fill;
    
  }
  
  .team-details-content .content-box{
    position: relative;
    display: block;
  }
  
  .team-details-content .content-box .designation{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 18px;width: fit-content;
    padding: 5px 12px;
      padding-top: 5px;
      padding-right: 12px;
      padding-bottom: 5px;
      padding-left: 12px;
    border-radius: 4px;
  }
  
  .team-details-content .content-box h2{
    display: block;
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 19px;
  }
  
  .team-details-content .content-box .social-links{
    position: relative;
    display: block;
    margin-bottom: 32px;
  }
  
  .team-details-content .content-box .social-links li{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 15px;
  }
  
  .team-details-content .content-box .social-links li:last-child{
    margin: 0px !important;
  }
  
  .team-details-content .content-box .social-links li a{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border: 1px solid #E5E5E5;
    color: #676767;
    text-align: center;
    border-radius: 50%;
  }
  
  .team-details-content .content-box .social-links li a:hover{
    color: #fff;
    background: var(--theme-color);
    border-color: var(--theme-color);
  }
  
  .team-details-content .content-box .text-box p{
    margin-bottom: 35px;
  }
  
  .team-details-content .content-box .text-box p:last-child{
    margin-bottom: 0px;
  }
  
  .team-details-content .content-box .info li{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  
  .team-details-content .content-box .info li:last-child{
    margin-bottom: 0px;
  }
  
  .team-details-content .content-box .info li strong{
    font-weight: 500;
  }
  
  .team-details-content .content-box .info li a{
    color: var(--text-color);
  }
  
  .team-details-content .content-box .info li a:hover{
    text-decoration: underline;
  }
  .team-details-content .content-box span.designation{
    padding: 0;
    color: #101A30;
    font-weight: 600;
  }
  .team-details h3{
    position: relative;
    display: block;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 21px;
  }
  
  .team-details .experience-skills .experience-inner p{
    margin-bottom: 36px;
  }
  
  .team-details .experience-skills .experience-inner p:last-child{
    margin-bottom: 0px;
  }
  
  .team-details .experience-skills h3{
    font-size: 28px;
  }
  
  .team-details .experience-skills .single-item{
    position: relative;
    display: block;
    padding-left: 30px;
    margin-bottom: 16px;
  }
  
  .team-details .experience-skills .single-item:last-child{
    margin-bottom: 0px;
  }
  
  .team-details .experience-skills .single-item:before{
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    left: 0px;
    top: 5px;
    border-radius: 50%;
    border: 1.5px solid var(--theme-color);
  }
  
  .team-details .experience-skills .single-item:after{
    position: absolute;
    content: '';
    background: var(--theme-color);
    width: 6px;
    height: 6px;
    left: 5px;
    top: 10px;
    border-radius: 50%;
  }
  
  .team-details .experience-skills .single-item h4{
    display: block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .team-details .experience-skills .single-item p span{
    display: block;
    font-weight: 500;
  } 
  