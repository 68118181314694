.project-style-two{
    position: relative;
    padding: 50px 0 50px 0;
  }
  
  .sortable-masonry .filter-tabs li{
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--title-font);
    color: var(--title-color);
    padding: 5px 20px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease;
    margin-right: 8px;
  }
  
  .sortable-masonry .filter-tabs li.active,
  .sortable-masonry .filter-tabs li:hover{
    color: #fff;
    background: var(--theme-color);
  }
  
  .project-block-two .inner-box{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  
  .project-block-two .inner-box .image-box{
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  
  .project-block-two .inner-box .image-box img{
    border-radius: 10px;
    transition: all 500ms ease;
    width:100%;
    height:100%;
    object-fit: fill;
  }
  
  .project-block-two .inner-box:hover .image-box img{
    transform: scale(1.05);
  }
  
  .project-block-two .inner-box .content-box{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    padding: 12px 5px 12px 5px;
    opacity: 1;
    z-index: 2;
    transition: all 500ms ease;
    text-align: center;
  }
  
  .project-block-two .inner-box .content-box:before{
    position: absolute;
    content: '';
    background: #535353;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0.7;
    z-index: -1;
    transition: all 500ms ease;
  }

  .project-block-two .inner-box .content-box p{
    display: block;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 5px;
    color: #fff;
  }
  
  .project-block-two .inner-box .content-box h4{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
  }
  
  .project-block-two .inner-box .content-box .link-box{
    position: absolute;
    top: -30px;
    right: 30px;
  }
  
  .project-block-two .inner-box .content-box .link-box a{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 64px;
    font-size: 18px;
    color: #fff;
    background: var(--theme-color);
    border-radius: 50%;
    text-align: center;
  }
  
  .project-block-two .inner-box .content-box .link-box a:hover{
    background: var(--secondary-color) !important;
  }
  
  
  
  
  