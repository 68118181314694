.scrapDetailsSec{
    padding: 70px 0 70px 0;
}

.text-green {
  color: var(--theme-color);
}
.bg-green {
  background-color: var(--theme-color);
}
.bestPrice {
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  background: var(--theme-color);
  padding: 2px 17px 0px 17px;
  border-radius: 0px 20px 20px 0px;
}
.scrapDetails-block2 .category {
  padding-left: 24px;
}
.category::before {
  position: absolute;
  content: '';
  background: var(--theme-color);
  width: 15px;
  height: 2px;
  left: 0px;
  top: 12px;
}


.scrapDetails-block1 .scrap-carousel .slide-item .bg-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(1);
    transition: all 8000ms linear;
  }
.scrapDetails-block1 .scrap-carousel .slide-item {
    position: relative;
    padding: 95px 0px 182px 0px;
  }


.scrapDetails-block1 .swiper-pagination-bullet{
    border: 5px solid var(--theme-color);
border-radius: 50%;
display: inline-block;
margin-right: 5px;
margin-top: 15px;
}
.scrapDetails-block1 .swiper-pagination-bullet.swiper-pagination-bullet-active{
    border-color:var(--secondary-color);
}
.scrapDetails-block1 .swiper-button-prev,
.scrapDetails-block1 .swiper-button-next{
    display: none;
}

.scrapDetails-tabs {
    background: #FFFFFF;
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 30px;
    margin-top: 1.5em;
  }
.scrapDetails-tabs ul li {
    margin-right:10px;
}



.scrapDetails .banner-carousel .carousel .control-prev.control-arrow,
.swiper-button-prev {
  left: 7px;
}

.scrapDetails .banner-carousel .carousel .control-prev.control-arrow,
.swiper-button-next {
  right: 7px;
}

.scrapDetails .banner-carousel .carousel .control-arrow,
.carousel.carousel-slider .control-arrow,
.swiper-button-prev,
.swiper-button-next {
  top: 45%
}

.scrapDetails .carousel.carousel-slider .control-arrow:hover,
.swiper-button-prev:hover,
.scrapDetails .carousel.carousel-slider .control-arrow:hover,
.swiper-button-next:hover {
  color: #000;
  background: #fff !important;
}

.scrapDetails .carousel.carousel-slider .control-arrow:hover,
.swiper-button-prev,
.scrapDetails .carousel.carousel-slider .control-arrow:hover,
.swiper-button-next {
  width: 60px;
  height: 40px;
  color: #fff;
  background: transparent !important;
}

.scrapDetails .banner-carousel .carousel .control-arrow,
.carousel.carousel-slider .control-arrow,
.swiper-button-prev,
.swiper-button-next {
  line-height: 42px;
}

.scrapListingd.blog-grid .news-block-two .inner-box {
  display: inherit;
}