/*** 

====================================================================
  Reset
====================================================================

 ***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}


/*** 

====================================================================
  Global Settings
====================================================================

 ***/


:root {
  --theme-color: #000248;
  --secondary-color: #258bd5;
  --pink-color: #FFC0CB;
  --violet-color: #7F00FF;
  --crimson-color: #DC143C;
  --orange-color: #FFA500;
  --text-color: #666;
  --title-color: #101A30;
  --text-font: 'Poppins', sans-serif;
  /* --title-font: 'Fraunces', serif; */
  --font-sans-fallback: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --themeColor: #4361ee;

  --font-size: 0.8em;
  --bg1: #f5f8f7;
  --bglight: #fafafb;
  --blue: #3498db;
  --green: #2ecc71;
  --purple: #9b59b6;
  --gold: #f1c40f;
  --red: #e74c3c;
  --orange: #e67e22;
  --shadow1: 0 2px 4px #00000026, 0 3px 6px #0000001f;
  --shadow2: 0 2px 6px #00000044, 0 4px 7px #00000022;

}





body {
  font-size: 16px;
  color: var(--text-color);
  line-height: 28px;
  font-weight: 400;
  background: #ffffff;
  font-family: var(--text-font);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-font-smoothing: antialiased;
}

@media (min-width:1200px) {
  .container {
    max-width: 1200px;
    padding: 0px 15px;
  }
}

ol,
ul {
  padding-left: 0rem !important;
}

.large-container {
  max-width: 1550px;
  padding: 0px 15px;
  margin: 0 auto;
}

.container-fluid {
  padding: 0px;
}

.auto-container {
  position: static;
  max-width: 1320px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.boxed_wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}


a {
  text-decoration: none;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.lightGreen {
  background: #EBF5E9 !important;
}

.bglight {
  background-color: var(--bglight);
}

a:hover {
  text-decoration: none;
  outline: none;
}

input,
number,
button,
select,
textarea {
  font-family: var(--text-font);
  font-weight: 400;
  background: transparent;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input {
  transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}

p {
  position: relative;
  /* font-family: var(--text-font); */
  color: var(--text-color);
  font-weight: 400;
  margin: 0px;
  transition: all 500ms ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  color: #101A30;
  margin: 0px;
  transition: all 500ms ease;
}

.lh-70 {
  line-height: 70px !important;
}

.h-45 {
  height: 45px !important;
}

.h-795 {
  height: 795px !important;
}

.shape-img-1 {
  background-image: url("../images/shape/shape-1.png");
}

.shape-img-2 {
  background-image: url("../images/shape/shape-2.png");
}

.shape-img-3 {
  background-image: url("../images/shape/shape-3.png");
}

.shape-img-5 {
  background-image: url("../images/shape/shape-5.png");
}

.shape-img-6 {
  background-image: url("../images/shape/shape-6.png");
}

.shape-img-7 {
  background-image: url("../images/shape/shape-7.png");
}

.shape-img-8 {
  background-image: url("../images/shape/shape-8.png");
}

.shape-img-13 {
  background-image: url("../images/shape/shape-13.png");
}

.shape-img-14 {
  background-image: url("../images/shape/shape-14.png");
}

.shape-img-15 {
  background-image: url("../images/shape/shape-15.png");
}

.shape-img-16 {
  background-image: url("../images/shape/shape-16.png");
}

.shape-img-18 {
  background-image: url("../images/shape/shape-18.png");
}

.shape-img-19 {
  background-image: url("../images/shape/shape-19.png");
}

.shape-img-20 {
  background-image: url("../images/shape/shape-20.png");
}

.shape-img-21 {
  background-image: url("../images/shape/shape-21.png");
}

.shape-img-22 {
  background-image: url("../images/shape/shape-22.png");
}

.shape-img-23 {
  background-image: url("../images/shape/shape-23.png");
}

.shape-img-24 {
  background-image: url("../images/shape/shape-24.png");
}

.icon-bg-img-1 {
  background-image: url("../images/icons/icon-bg-1.png");
}

.h-500 {
  height: 500px;
}

/* color */
.textLightGreen {
  color: var(--theme-color);
}

.textClr {
  color: var(--text-color);
}

.theme-btn.btn-two {
  border: 1px solid var(--theme-color) !important;
  color: var(--theme-color) !important;
}
.theme-btn.btn-two:hover span{
  color:#fff;
}

.bg-color{
  background-color: var(--theme-color);
}

/* label */
.lableGreen {
  background: rgba(0, 208, 156, .1);
  color: #00d09c;
}

.lableLightBlue {
  background: rgba(0, 207, 255, .1);
  color: #00cfff;
}

.lableOrange {
  background: rgba(255, 138, 91, .1);
  color: #ff8a5b;
}

.lableBlue {
  background: rgba(0, 122, 255, .1);
  color: #007aff;
}

/* label */

.pageTitle {
  /* background-image: url("../images/background/page-title.jpg"); */
  /* background: linear-gradient(90deg, #e3ffe7, #d9e7ff); */
  background: linear-gradient(180deg, #eaf8ff, #f6f6ff);
}

.br-1 {
  border-radius: 20px 0px 0px 20px !important;
}

.p-b {
  padding: 5px 12px !important;
}

.mt-n100 {
  margin-top: -100px;
}

.h-400 {
  height: 400px;
}

.main-header .theme-btn.btn-two {
  border: 1px solid var(--theme-color);
}

.main-header .theme-btn.btn-two span {
  color: var(--theme-color);
  padding: 12px 16px;
}

.main-header .theme-btn.btn-two:hover span {
  color: #fff;
}

/** clients-section **/
.clients-section{
  position: relative;
  background: linear-gradient(180deg,#fef7e2,#eeeefd);
  padding: 50px 0;
}
.clients-section .pattern-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.clients-section .clients-logo{
  position: relative;
  display: block;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  max-width: 240px;
  margin: 0 auto;
  transition: all 500ms ease;
}
.clients-section .clients-logo img{
  width: 100%;
}

.clients-section .clients-logo:hover{
  background: #fff;
}
.clients-section .clients-logo a{
  position: relative;
  display: inline-block;
}
.clients-section .swiper-button-next,
.clients-section .swiper-button-prev{
  width: 40px;
  height: 25px;
  text-align: center;
  margin-right: 13px;
  border-radius: 5px;
  line-height: 25px !important;
  position: relative;
  top: -32%;
  left: 45%;
  float: left;
  color: #fff;
  background-color: var(--theme-color) !important;
  z-index: 1;
}
.clients-section .swiper-button-next:hover,
.clients-section .swiper-button-prev:hover{
  color: #000;
  background: #fff !important;
}
.clients-section .swiper{
  height:120px;
}
/** page-title **/
.page-title{
  display: none;
  padding: 15px 0 15px;
  overflow: hidden;
}
.page-title .content-box{
  text-align: center;
}
.page-title .bg-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.page-title h1{
  position: relative;
  display: block;
  font-size: 25px;
  line-height: 4px;
  font-weight: 700;
  /* margin-bottom: 14px;   */
  color: #000248;
  font-family: DM Sans,sans-serif;
}
.page-title .bread-crumb li{
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  padding-right: 15px;
  margin-right: 5px;
  color: #000248;
  font-family: DM Sans,sans-serif;
}
.page-title .bread-crumb li:last-child{
  padding: 0px !important;
  margin: 0px !important;
}
.page-title .bread-crumb li a{
  display: inline-block;
  color: #000248;
  font-family: DM Sans,sans-serif;
  font-weight: 600;
}
.page-title .bread-crumb li a:hover{
  color: var(--theme-color);
}
.page-title .bread-crumb li:before{
  position: absolute;
  content: "\f105";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  top: 0px;
  right: 0px;
}
.page-title .bread-crumb li:last-child:before{
  display: none;
}
.page-title .pattern-layer .pattern-1{
  position: absolute;
  left: 157px;
  bottom: -70px;
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.page-title .pattern-layer .pattern-2{
  position: absolute;
  top: 45px;
  right: 160px;
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.page-title .pattern-layer .pattern-3 {
  position: absolute;
  left: 70px;
  bottom: 80px;
  width: 113px;
  height: 108px;
  background-repeat: no-repeat;
}

.page-title .pattern-layer .pattern-4 {
  position: absolute;
  right: 70px;
  top: 165px;
  width: 115px;
  height: 115px;
  background-repeat: no-repeat;
}

/** radio button **/
.pricing-section .tab-btns:before{
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 40px;
  width: 70px;
  content: "";
  border: 1px solid var(--themeColor);
  border-radius: 20px;
}
.pricing-section .tab-btns li{
  position: relative;
  display: inline-block;
  min-width: 145px;
  font-size: 18px;
  padding: 6px 40px 6px 0px;
  line-height: 28px;
  color: #101A30;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 500ms ease;
}
.pricing-section .tab-btns li:last-child{
  padding-right: 0;
  padding-left: 55px;
  text-align: left;
}
.pricing-section .tab-btns li.active-btn:before {
  right: -2px;
}
.pricing-section .tab-btns li:before {
  position: absolute;
  top: 5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  content: "";
  right: -32px;
  background: #fff;
  background: var(--theme-color);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.pricing-section .tab-btns li:last-child:before{
  display: none;
}

/** end radio button **/



/** start compliance **
.complianceSec{
  background: linear-gradient(180deg,#fef7e2,#eeeefd);
  padding: 50px 0px 50px 0px;
}
.complianceSec .category-list li a {
  text-align: left;
}

.complianceSec .side1 .cmpSidebar {
  padding: 0;
  perspective: 5000px;
  transform: rotateY(4deg);
  transform-style: preserve-3d;
  background: linear-gradient(180deg,#eaf8ff,#f6f6ff);
  transition: all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s;
  border: 1px solid #eaf8ff;
  border-radius: 10px;
  border-top-left-radius: 4%;
  position: relative;
  display: block;
}
.complianceSec .side1 .cmpSidebar:hover {
  transform: perspective(1000px) rotateX(5deg) rotateY(2deg) scale3d(1, 1, 1);
}
.complianceSec .side1 .cmpSidebar .widget-title {
  padding: 35px 0;
  background: transparent;
  position: relative;
display: block;
}
.complianceSec .side1 .cmpSidebar .widget-title h3 {
  background: linear-gradient(to right, #ff8a00, #e52e71);
    background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.complianceSec .side1 .cmpSidebar .widget-content {
  padding: 15px;
}
.complianceSec .complianceBox {
  position: relative;
  padding: 60px 36px;
  z-index: 2;
  height: 481px;
}
.complianceSec .cTabs {
  border: 5px solid red
}
.complianceSec .ul.nav-pills .li {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.complianceSec .ul.nav-pills .li a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: #101A30;
  padding: 6px 15px;
  border-radius: 5px;
  text-align: left;
  border: 1px dotted #101A30;
  background-color: inherit;
}
.complianceSec .ul.nav-pills .li a:hover {
  color: #101A30;
  background: linear-gradient(180deg,#eaf8ff,#f6f6ff);
}
.complianceSec .ul.nav-pills .li a span {
  position: relative;
  transition: all 500ms ease;
}
.complianceSec .ul.nav-pills .li a span::before {
  position: absolute;
  content: '\e928';
  font-family: 'icomoon';
  font-size: 12px;
  font-weight: 400;
  opacity: 0;
  left: 0px;
  top: -4px;
  color: var(--title-color);
  transition: all 500ms ease;
}
.complianceSec .ul.nav-pills .li a:hover span {
  padding-left: 24px;
}
.complianceSec .ul.nav-pills .li a:hover span::before {
  opacity: 1;
}
**/

/* .solution-section .indList{
    font-size: 14px;
    color: #666;
    background: #fafafa;
    border: 1px solid #eaeaea;
    padding: 6px 15px 6px 15px;
    border-radius: 12px;
    margin-bottom:7px;
    transition: all 500ms ease;
}
.solution-section h4{
  border-left: 3px solid var(--themeColor);
    padding-left: 8px;
}
.solution-section.sec-pad {
  padding: 35px 0px 35px 0px;
  
}
.solution-section .indList i{
  color: var(--theme-color);
}
.solution-section .indList:hover{
  background-color:#fff;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(175, 176, 176, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.solution-section .solutionImg img{
  height:420px;
} */


.userBtn .btn,
.userBtn .btn.show{
  background-color: transparent;
  border: none;
  padding: 0;
}
.userBtn .btn:active{
  background-color: transparent;
}
.userBtn .btn img{
  width: 30px;
  border-radius: 50%
}
.userBtn .dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 12px;
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-bottom: 1px solid #f9f9f9;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  font-size: 13px;
}
.userBtn .dropdown-item:hover {
  background-color: #f2f2f2;
}
.userBtn .dropdown-menu.show{
  min-width: 8rem;
}







/* Preloader */

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  background: var(--theme-color);
}

.preloader-close {
  position: fixed;
  z-index: 99999999;
  font-size: 14px;
  background: #fff;
  color: red;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  top: 30px;
  font-weight: 400;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px auto;
  width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: var(--title-font);
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.30);
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.handle-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  color: #ffffff;
}

.handle-preloader .animation-preloader .spinner {
  border: 3px solid #ffffff;
  border-top-color: rgba(255, 255, 255, 0.5);
}

/* AnimaciÃ³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}


.centred {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.special_fonts {
  font-family: 'Fredoka', sans-serif;
}


figure {
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.row {
  --bs-gutter-x: 10px;
}





/* 
.auctionPage ul.listingView li button {
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}
.auctionPage ul.listingView li.active button {
  background-color: var(--theme-color);
  color: #fff;
}
.auctionPage .image-box img{
  width: 300px;
  height: 170px;
} 
.auctionPage .block-1 a.theme-button {
  height: auto;
  line-height: 8px;
  padding: 12px 8px;
  font-size: 15px;
  font-weight: 600;
}
.auctionPage .lower-content .block-2 p{
  font-size: 15px;
}
.auctionPage .listView .inner-box .image-box{
  width:40%;
  float:left;
}
.auctionPage .listView .inner-box .lower-content{
  width: 60%;
float: right;
border-top: 0 !important;
height: 100%;
background: #f7fafc;
border-left: 1px solid #edf2f7;
}
.auctionPage .listView .inner-box .lower-content .block-1{
  background-color: #fff;
}
.auctionPage .listView .inner-box .lower-content .block-2{
border-radius: 0;
}
.auctionPage .auctionBid .form-group input[type="text"] {
  width: 75px;
  height: 25px;
  font-size: 13px;
  padding: 0px 4px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0;
}
.auctionPage .block-2 .auctionBid .form-group .theme-button {
  height: auto;
line-height: 8px;
padding: 0px 4px;
font-size: 14px;
font-weight: 600;
margin: 0;
border:1px solid #dee2e6;
border-left:0;
}
.auctionPage .block-2 .auctionBid .form-group .theme-button::before {
  border: none;
} */

/********* tabbing **********/
.nav.nav-tabs {
  border: none;
  margin-bottom: 2rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--theme-color);
  color: #fff;
}

.nav-tabs .nav-link {
  border: none;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  background-color: var(--secondary-color);
  padding: 6px 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
  margin: 0px;
  /* transition: all 500ms ease; */
  font-size: 1rem;
  transition: none;
}

.scrapListingd .nav-tabs .nav-item {
  padding-right: 1em;
}

.scrapListingd  {
  background: #FFFFFF;
  box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 22px;
}

.scrapListingd .inner-box .image-box{
  width: 282px;
height: 185px;
}
.scrapListingd .inner-box .image-box .image{
  width: 282px;
  height: 185px;
}
.scrapListingd .inner-box .image-box .image img{
  height: 100%;
  object-fit: fill;
}
/******** scrapListingd  ********/
/* .scrapListingd{
  padding: 70px 0 70px 0;
}
.scrapListingd .news-block-two {
  background: #FFFFFF;
  box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 22px;
}

.scrapListingd .news-block-two .inner-box .lower-content {
  padding-top: 0px;
}

.scrapListingd .avalable {
  padding: 2px 5px;
  color: #fff;
  font-weight: bold;
  border-top-left-radius: 13px;
  border-bottom-right-radius: 10px;
}

.scrapListingd .news-block-two .inner-box {
  display: flex;
}

.scrapListingd .lower-content{
  margin-top: 8px;
} */




.forgotPage img{
  width:171px;
}

/* accordian */
/* .accordion-item {
  position: relative;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,.12);
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}

.accordion-header {
  position: relative;
  display: block;

  background: #fff;
  cursor: pointer;
  transition: all 500ms ease;
}

.accordion-button {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  transition: all 500ms ease;
  border-radius: 0;
  padding: 0;
  padding: 17px 60px 17px 30px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background: inherit;
  box-shadow: none;
  padding: 17px 60px 17px 30px;
  background: var(--theme-color) !important;
} */


/** button **/

.theme-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: var(--text-font);
  color: #fff !important;
  text-align: center;
  border-radius: 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.theme-btn.shadow {
  box-shadow: 0 5px 10px rgba(0,0,0,.12) !important;
}

.theme-btn.btn-one {
  background: var(--theme-color);
}

.theme-btn:before,
.theme-btn:after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--theme-color);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: center;
  transform: scale(0) rotate(0);
  z-index: -1;
}

.theme-btn:hover:before {
  border-radius: 0;
  transform: scale(1) rotate(-180deg);
}

.theme-btn:hover:after {
  border-radius: 0;
  transform: scale(1) rotate(180deg);
}

.theme-btn:after {
  background: var(--theme-color);
}

.theme-btn.btn-two {
  border: 2px solid #fff;
}

.theme-btn.btn-two span {
  padding: 10px 24px;
}
.theme-btn.btn-two span:hover{
  color:#fff;
}
.theme-btn span {
  position: relative;
  display: inline-block;
  padding: 18px 32px;
  font-weight: 500;
}

.theme-btn span:before {
  position: absolute;
  content: '';
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  left: -10px;
  top: -10px;
  border-radius: 50%;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.theme-btn:hover span:before {
  transform: scale(1, 1);
}

.theme-btn span:after {
  position: absolute;
  content: '';
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.theme-btn:hover span:after {
  transform: scale(1, 1);
}

.parallax-bg {
  position: absolute;
  left: 0px;
  top: -30%;
  width: 100%;
  height: calc(100% + 30%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


/** styled-pagination */


.sec-pad {
  padding: 142px 0px 150px 0px;
}


.mr-0 {
  margin: 0px !important;
}

.fpagination {
  position: relative;
  display: block;
}

.fpagination li {
  position: relative;
  display: inline-block;
  margin: 0px 4px;
}

.fpagination li a {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #E5E5E5;
  text-align: center;
  color: #222;
  border-radius: 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.fpagination li a:hover,
.fpagination li a.current {
  color: #fff;
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.fpagination li a i {
  font-size: 20px;
}
/** scroll-to-top **/

.scroll-to-top {
  position: fixed;
  right: 0px;
  bottom: 100px;
  transform: rotate(90deg);
  z-index: 99;
}

.scroll-to-top .visible {
  visibility: visible !important;
  opacity: 1 !important;
}

.scroll-to-top .scroll-top-inner {
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top .scroll-top-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all cubic-bezier(.4, 0, .2, 1) .4s;
  -o-transition: all cubic-bezier(.4, 0, .2, 1) .4s;
  transition: all cubic-bezier(.4, 0, .2, 1) .4s;
}

.scroll-to-top .scroll-bar {
  width: 50px;
  height: 2px;
  margin-right: 10px;
  position: relative;
}

.scroll-to-top .scroll-bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4244f;
  opacity: .3;
}

.scroll-to-top .scroll-bar .bar-inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: currentColor;
}

.scroll-to-top .scroll-bar-text {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--theme-color);
  transition: all 500ms ease;
}

.scroll-to-top .scroll-bar-text:hover {
  transform: scale(1.1);
}



@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}



.sec-title {
  position: relative;
  display: block;
}

/* .sec-title .sub-title {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: var(--theme-color);
  margin-bottom: 18px;
} */

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
  margin: 0px;
}

.sec-title.light .sub-title,
.sec-title.light h2 {
  color: #fff !important;
}

.sec-title p {
  margin-top: 22px;
}





/*** 

====================================================================
                        Home-Page-One
====================================================================

***/


/** main-header **/

.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 999;
  width: 100%;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -webkit-transition: all 100ms ease;
  -ms-transition: all 100ms ease;
  -o-transition: all 100ms ease;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 150ms;
  -moz-animation-duration: 150ms;
  -op-animation-duration: 150ms;
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/** header-top **/

.header-top {
  position: relative;
  width: 100%;
  background: #2a3fa0;
  padding: 13px 70px;
}

.header-top .top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-top .info-list li {
  position: relative;
  display: inline-block;
  /* float:left; */
  font-size: 16px;
  line-height: 24px;
  color: #A5A4B3;
  font-weight: 500;
  padding-left: 23px;
  margin-right: 28px;
}

.header-top .info-list li:last-child {
  margin: 0px !important;
}

.header-top .info-list li a,
.header-top .info-list li span {
  color: #fff;
}

.header-top .info-list li a:hover {
  text-decoration: underline;
}

.header-top .info-list li i {
  position: absolute;
  left: 0px;
  top: 4px;
  color: #fff !important;
  font-size: 16px;
}

.header-top .info-list li:last-child i {
  font-size: 20px;
  top: 2px;
}

.header-top .top-right {
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .top-right .login-box {
  position: relative;
  padding-right: 30px;
  margin-right: 30px;
}

.header-top .top-right .login-box:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.1);
  width: 1px;
  height: 50px;
  top: -13px;
  right: 0px;
}

.header-top .top-right .login-box a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
}

.header-top .top-right .login-box a:hover,
.header-top .social-links li a:hover {
  color:#fff !important;
}

.header-top .social-links li {
  float:left;
  margin-right: 15px;
  border: 1px solid #fff;
  border-radius: 10%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
}

.header-top .social-links li:last-child {
  margin: 0px !important;
}
.header-top .social-links li:hover {
  background-color: var(--secondary-color);
  border:1px solid var(--secondary-color);
}
.header-top .social-links li a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
}

.main-header .logo-box .logo img {
  width: 80%;
  object-fit: scale-down;
  /* height:80px; */
}


/*** Search Popup ***/

/* .search-popup,
.process-popup {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.50);
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
} */

.search-popup,
.process-popup {
  position: fixed;
  left: 0;
  top: 32%;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  /* background: rgba(0, 0, 0, 0.50); */
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}

.process-popup{
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.search-popup.popup-visible {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.search-popup .popup-inner {
  width: 100%;
  background: #fff;
  height: 100%;
}

.process-popup .popup-inner {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  background: #eee;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .03), 0 2px 4px rgba(0, 0, 0, .05), 0 12px 24px rgba(0, 0, 0, .05);
}

.search-popup .upper-box {
  position: relative;
  padding: 70px 70px;
  z-index: 99;
}


.process-popup .upper-box h4{
  background: #eee;
border-radius: 8px;
box-shadow: 0 0 0 1px rgba(0,0,0,.03),0 2px 4px rgba(0,0,0,.05),0 12px 24px rgba(0,0,0,.05);
padding: 6px 26px;
filter: drop-shadow(0 1.5em 1em rgba(0,0,0,.05));
}
.process-popup i{
  border: 1px dotted var(--themeColor);
padding: 2px;
border-radius: 18%;
font-size: 20px;
}

.search-popup .upper-box .logo-box {
  max-width: 182px;
}

.search-popup .overlay-layer,
.process-popup .overlay-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
}

/* .search-popup .close-search,
.process-popup .close-search {
  position: relative;
  color: #141417;
  top: 11px;
} */



.search-popup .close-search:hover {
  color: red;
}




.search-popup .search-form {
  position: relative;
  width: 100%;
  padding: 100px 0px 250px 0px;
}

.search-popup .search-form .form-group {
  position: relative;
  margin: 0px;
}

.search-popup .search-form fieldset input[type="search"] {
  position: relative;
  height: 90px;
  padding: 20px 0px;
  background: #ffffff;
  line-height: 30px;
  font-size: 20px;
  color: #808080;
  font-family: 'Poppins', sans-serif;
  border: none;
  font-weight: 400;
  border-radius: 0px;
  padding-right: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.search-popup .search-form fieldset button[type="submit"] {
  position: absolute;
  top: 30px;
  right: 0px;
  font-size: 25px;
  color: #141417;
  cursor: pointer;
  transition: all 500ms ease;
}

.search-popup .search-form fieldset input[type="search"]:focus {
  border-color: #141417;
}

.search-popup .form-control:focus {
  box-shadow: none !important;
}

.main-header .logo-box {
  position: relative;
  padding: 18px 0px;
}

.main-header .outer-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 70px;
}

.main-header .menu-area,
.main-header .menu-right-content {
  position: relative;
  display: flex;
  align-items: center;
}
.menu-right-content .theme-btn{
  border-radius:0
}
.menu-right-content .theme-btn span{
  padding: 11px 32px;
}
.main-header .main-menu {
  position: relative;
  padding-right: 30px;
  margin-right: 30px;
}

/* .main-header .main-menu:before {
  position: absolute;
  content: '';
  background: #E5E5E5;
  width: 1px;
  height: 50px;
  top: 25px;
  right: 0px;
} */

.justify{
  text-align: justify;
}


.main-header .menu-right-content .support-box {
  position: relative;
  display: block;
  padding-left: 33px;
  margin-right: 30px;
}

.main-header .menu-right-content .support-box i {
  position: absolute;
  left: 0px;
  top: 2px;
  font-size: 20px;
  color: var(--theme-color);
}

.main-header .menu-right-content .support-box a {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 26px;
  color: #222;
  font-weight: 500;
}

.main-header .menu-right-content .support-box a:hover {
  color: var(--theme-color);
}

.main-header .menu-right-content .search-box-outer {
  margin-right: 30px;
  margin-left: 30px;
}

.main-header .menu-right-content .search-box-outer {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 54px;
  font-size: 20px;
  color: #222;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: all 500ms ease;
}

.main-header .menu-right-content .search-box-outer:hover{
  color: #fff;
  background: var(--theme-color);
  border-color: var(--theme-color);
}

/** main-menu **/

.main-menu {
  float: left;
}

.main-menu .navbar-collapse {
  padding: 0px;
  display: block !important;
}

.main-menu .navigation {
  margin: 0px;
}

.main-menu .navigation>li {
  position: inherit;
  float: left;
  z-index: 2;
  margin: 0px 12px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li:last-child {
  margin-right: 0px !important;
}

.main-menu .navigation>li:first-child {
  margin-left: 0px !important;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 17px;
  line-height: 26px;
  padding-top: 26px;
  padding-bottom: 26px;
  font-weight: 500;
  font-family: var(--text-font);
  opacity: 1;
  color: #222;
  z-index: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation>li.dropdown>a {
  padding-right: 17px;
}

/* .main-menu .navigation>li.dropdown>a:before {
  position: absolute;
  content: "\f107";
  font-family: 'Font Awesome 5 Pro';
  top: 37px;
  right: 0px;
  font-weight: 500;
  transition: all 500ms ease;
} */

.main-menu .navigation>li.dropdown>a:before {
  position: absolute;
  content: "\f107";
  font-family: 'Font Awesome 5 Pro';
  top: 27px;
  right: 0px;
  font-weight: 500;
  transition: all 500ms ease;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: inherit;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}


.main-menu .navigation>li>ul,
.main-menu .navigation>li>.megamenu {
  position: absolute;
  left: inherit;
  top: 100%;
  width: 230px;
  margin-top: 15px;
  z-index: 100;
  display: none;
  background: var(--theme-color);
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-menu .navigation>li>ul {
  position: absolute;
  left: inherit;
  top: 100%;
  width: 175px;
  margin-top: 15px;
  z-index: 100;
  display: none;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}


.main-menu .navigation>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li {
  position: relative;
  width: 100%;
}

/* .main-menu .navigation>li>ul>li>a,
.main-menu .navigation>li>.megamenu li>a {
  position: relative;
  display: block;
  padding: 10px 25px;
  line-height: 24px;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  font-family: var(--text-font);
  color: #fff;
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
} */
.dropdown-menu .dropdown-item a{
  color:var(--theme-color);
}
/* .dropdown-menu .dropdown-item.active{
  background-color: inherit;
} */
.dropdown-menu .dropdown-item:active {
  color: var(--secondary-color);
  text-decoration: none;
  background-color: inherit;
}

.main-menu .navigation>li>ul>li>a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 17px;
}

.main-menu .navigation>li>.megamenu li>a {
  padding-left: 0px;
}

.main-menu .navigation>li>.megamenu h4 {
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

/* .main-menu .navigation>li>ul>li>a:hover,
.main-menu .navigation>li>.megamenu li>a:hover {
  padding-left: 35px;
} */

.main-menu .navigation>li>ul>li:last-child>a,
.main-menu .navigation>li>.megamenu li:last-child>a {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 100%;
  top: 0%;
  margin-top: 15px;
  background: var(--theme-color);
  width: 230px;
  z-index: 100;
  display: none;
  border-radius: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  width: 100%;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  padding: 10px 25px;
  line-height: 24px;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  font-family: var(--text-font);
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:last-child>a {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
  padding-left: 35px;
}

.main-menu .navigation>li>ul>li>ul>li.dropdown>a:after {
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
}

.main-menu .navigation>li.dropdown:hover>ul,
.main-menu .navigation>li.dropdown:hover>.megamenu {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
  top: 100%;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 0%;
  margin-top: 0px;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: -32px;
  top: 66px;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: #3b3b3b;
  cursor: pointer;
  display: none;
  z-index: 5;
  transition: all 500ms ease;
}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn {
  display: none;
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  background: var(--secondary-color);
  display: none;
  padding: 15px !important;
  margin-right: 8px;
}

.mobile-menu .nav-logo img {
  max-width: 160px;
}

.menu-area .mobile-nav-toggler .icon-bar {
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.header-style-three .menu-area .mobile-nav-toggler .icon-bar {
  background: #222;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child {
  margin-bottom: 0px;
}


/** megamenu-style **/

.main-menu .navigation>li.dropdown>.megamenu {
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li h4 {
  margin-bottom: 10px;
}

.sticky-header .main-menu .navigation>li>a {
  padding-top: 27px;
  padding-bottom: 27px;
}

.sticky-header .main-menu .navigation>li.dropdown>a:before {
  top: 27px;
}

.sticky-header .main-menu:before {
  top: 15px;
}


/** mobile-menu **/

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 900ms ease;
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 10px 25px;
  text-align: left;
  background-color: #fff;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 900ms ease;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 0.70;
  visibility: visible;
  right: 100%;
  transition: all .8s ease-out 0s;
  -webkit-transition: all .8s ease-out 0s;
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #141417;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 900ms ease !important;
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 25px;
  top: 20px;
  line-height: 24px;
  width: 24px;
  height:24px;
  text-align: center;
  font-size: 18px;
  color: var(--theme-color);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
  border: 1px solid var(--theme-color);
border-radius: 100%;
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile-menu .close-btn:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>ul>li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li ul li>a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li>a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  border-left: 5px solid #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.current>a:before {
  height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.10);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
  color: #ffffff;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul,
.mobile-menu .navigation>li.dropdown>.megamenu {
  display: none;
}

.mobile-menu .social-links {
  position: relative;
  padding: 0px 25px;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}


div#mCSB_1_container {
  top: 0px !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 30px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.80);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a {
  color: rgba(255, 255, 255, 0.80);
}

.mobile-menu .contact-info ul li:last-child {
  margin-bottom: 0px;
}

.main-header .outer-box {
  position: relative;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav {
  display: none !important;
}

.owl-nav button {
  background: transparent;
}

.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.float-bob-x {
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}


/** rtl-switcher **/

.demo-rtl {
  position: fixed;
  top: 390px;
  left: 10px;
  z-index: 9999;
}

button.rtl {
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms ease;
}

.demo-ltr {
  position: fixed;
  top: 390px;
  left: auto;
  right: 10px;
  z-index: 9999;
}

button.ltr {
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 1.0);
  transition: all 500ms ease;
}

.boxed_wrapper.ltr .demo-rtl {
  display: block;
}

.boxed_wrapper.ltr .demo-ltr {
  display: none;
}

.boxed_wrapper.rtl .demo-rtl {
  display: none;
}

.boxed_wrapper.rtl .demo-ltr {
  display: block;
}


.bg-color-1 {
  background-color: #ECFEF4;
}


/** main-footer **/

.main-footer {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.main-footer .footer-top {
  position: relative;
  padding: 40px 0px 20px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-footer .footer-logo {
  margin-bottom: 5px;
}

.main-footer .logo-widget .text-box p {
  margin-bottom: 26px;
}

.main-footer .logo-widget .text-box p:last-child {
  margin-bottom: 0px;
}

.main-footer p,
.main-footer a {
  /* color: var(--text-color); */
  /* color: #fff; */
}

.main-footer a:hover {
  color: #000248
}

.main-footer .widget-title {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding: 0;
}
.main-footer .widget-title h4{
  font-size: 1rem;
}

.main-footer .widget-title h3 {
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #000248;
  font-family: DM Sans, sans-serif;
}

.main-footer .links-widget .links-list li {
  /* position: relative; */
  display: block;
}

.main-footer .links-widget .links-list li a {
  display: inline-block;
  font-size: 16px;
  line-height: 40px;
  /* color: black; */
}

.main-footer .schedule-widget p {
  margin-bottom: 22px;
}

.main-footer .schedule-widget .schedule-list li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
}

.main-footer .contact-widget .info-list li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 32px;
  color: #757589;
  padding-left: 30px;
}
.copyright p{
  color: #fff;
}
.copyright p a{
  color: darkblue;
}
.main-footer .contact-widget .info-list li i {
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 21px;
  color: var(--theme-color);
}

.main-footer .contact-widget .info-list li a {
  display: inline-block;
  color: #999ab6;
}

.main-footer .contact-widget .info-list li a:hover {
  color: var(--theme-color);
}

.main-footer .contact-widget .info-list li:nth-child(2) i {
  font-size: 14px;
  top: 9px;
}

.main-footer .contact-widget .social-links li {
  position: relative;
  display: inline-block;
  /* float: left; */
  margin-right: 10px;
}

.main-footer .contact-widget .social-links li:last-child {
  margin: 0px !important;
}

.main-footer .contact-widget .social-links li a {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  color: var(--text-color);
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 50%;
}

.main-footer .contact-widget .social-links li a:hover {
  background: var(--theme-color) !important;
  color: #fff !important;
}

.footer-bottom {
  position: relative;
  width: 100%;
  padding: 20px 0px;
}

.footer-bottom .copyright p {
  font-weight: 500;
}

.main-footer .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}


/** header-style-two **/

.header-style-two {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.header-style-two .header-lower .main-menu .navigation>li>a {
  color: #fff;
}

.header-style-two .header-lower .main-menu {
  padding: 0px;
  margin: 0px;
}

.header-style-two .header-lower .main-menu:before {
  display: none;
}

.header-style-two .header-lower .outer-box {
  padding-right: 0px;
}

.header-style-two .header-lower .menu-right-content {
  padding: 20px 70px;
  background: var(--theme-color);
  border-radius: 50px 0px 0px 50px;
}

.header-style-two .header-lower .menu-right-content .support-box i,
.header-style-two .header-lower .menu-right-content .support-box a {
  color: #fff !important;
}

.header-style-two .header-lower .menu-right-content .support-box a:hover {
  text-decoration: underline;
}

.header-style-two .header-lower .menu-right-content .search-box-outer {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.header-style-two .header-lower .menu-right-content .theme-btn {
  background: rgba(255, 255, 255, 0.15) !important;
}


.main-footer .about-widget .text-box p {
  margin-bottom: 26px;
}

.main-footer .about-widget .text-box p:last-child {
  margin-bottom: 0px;
}

.main-footer .footer-upper {
  position: relative;
  padding-top: 100px;
}

.main-footer .footer-upper .upper-inner {
  position: relative;
  display: flex;
  padding: 30px 60px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}

.main-footer .footer-upper .footer-logo {
  margin-bottom: 0px;
}

.main-footer .footer-upper .right-column {
  position: relative;
  display: flex;
  align-items: center;
}

.main-footer .footer-upper .right-column h3 {
  display: block;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  margin-right: 30px;
}

.main-footer .footer-upper .form-inner {
  position: relative;
  width: 482px;
}

.main-footer .footer-upper .form-inner .form-group {
  position: relative;
  margin: 0px;
  padding-right: 184px;
}

.main-footer .footer-upper .form-inner .form-group input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 10px;
  border: 1px solid transparent;
  font-size: 16px;
  color: #fff;
  padding: 10px 30px;
  transition: all 500ms ease;
}

.main-footer .footer-upper .form-inner .form-group input:focus {
  border-color: var(--theme-color);
}

.main-footer .footer-upper .form-inner .form-group .theme-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 174px;
}


.tabs-box .tab {
  position: relative;
  display: none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
  display: block;
}

.tabs-box .tab {
  transform: scale(0.9, 0.9) translateY(0px);
}

.tabs-box .tab.active-tab {
  transform: scale(1) translateY(0px);
}

.default-form .form-group {
  position: relative;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.default-form {
  position: relative;
  margin: 0px 5px;
}

.default-form .form-group:last-child {
  margin-bottom: 0px;
}

.default-form .form-group input[type='text'],
.default-form .form-group input[type='number'],
.default-form .form-group input[type='password'],
.default-form .form-group input[type='email'],
.default-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  font-size: 16px;
  color: #101A30;
  padding: 10px 20px;
  text-align: left;
  transition: all 500ms ease;
}

.default-form .form-group input:focus,
.default-form .form-group textarea:focus {
  border-color: var(--theme-color);
}

.default-form .form-group textarea {
  height: 120px;
  resize: none;
}

.nav-style-one button {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 74px;
  background: #FFFFFF;
  box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  font-size: 20px;
  color: #222;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  margin: 0px 5px;
  transition: all 500ms ease;
}

.nav-style-one button:hover {
  color: #fff;
  background: var(--theme-color);
}


/****** sidebar ******/

.sidebar .mobile-menu {
  position: fixed;
  left: 0px;
  top: 54px;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
  transition: all 900ms ease;
  transform: translateX(0%);
}

.sidebar .mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #141417;
  padding: 0px 0px;
  z-index: 5;
  opacity: 1;
  visibility: visible;
  border-radius: 0px;
  transform: inherit;
  transition: all 900ms ease !important;
}



/******** Seller Panel ********/
.sellerPanel {
  margin-left: 300px;
}

.sellerPanel .form-inner {
  position: relative;
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 70px;
}

.card-title{
  
}


section.page-title{
  display: none;
}