/** about-section **/

.about-section {
    position: relative;
    padding: 35px 0px 35px 0;
    overflow: hidden;
  }
  .about-section .content_block_one .content-box{
    position: relative;
    display: block;
  }
  .about-section .content_block_one .content-box p{
    margin-bottom: 25px;
  }
  .about-section .list-style-one {
    position: relative;
    display: block;
  }
  .about-section .list-style-one li {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #101A30;
    font-weight: 500;
    padding-left: 27px;
    margin-bottom: 10px;
  }
  .about-section .list-style-one li:last-child {
    margin-bottom: 0px;
  }
  .about-section .list-style-one li:before {
    position: absolute;
    content: '\e90a';
    font-family: 'icomoon';
    font-size: 8px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 20px;
    background: var(--theme-color);
    text-align: center;
    border-radius: 50%;
    left: 0px;
    top: 4px;
    font-weight: 400;
  }
  .image_block_one .image-box{
    position: relative;
    display: block;
  }
  .image_block_one .image-box .image-shape{
    position: absolute;
    top: -74px;
    right: -73px;
    width: 180px;
    height: 180px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 4s infinite linear;
    animation: zoom-fade 4s infinite linear;
    z-index: 1;
  }
  .image_block_one .image-box .image-1{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    height: 650px;
  }
  .image_block_one .image-box .image-2{
    position: absolute;
    left: 0px;
    bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 20px 100px rgba(6, 49, 55, 0.2);
    border-radius: 20px;
    border: 8px solid #fff;
    overflow: hidden;
    z-index: 1;
    width:200px;
    height:220px;
  }
  .image_block_one .image-box .image-3{
    position: absolute;
    left: 0px;
    top: 140px;
    background: #FFFFFF;
    box-shadow: 0px 20px 100px rgba(6, 49, 55, 0.2);
    border-radius: 20px;
    border: 8px solid #fff;
    overflow: hidden;
    z-index: 1;
    width:330px;
    height:234px;

  }
  .image_block_one .image-box .image-1:before{
    position: absolute;
    top: 0;
    left: -85%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
    z-index: 1;
  }
  .image_block_one .image-box .image-2:before,
  .image_block_one .image-box .image-3:before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .image_block_one .image-box .image-1 img{
    width: 100%;
    border-radius: 20px;
    height: 100%;
    object-fit: fill;
  }
  .image_block_one .image-box .image-2 img,
  .image_block_one .image-box .image-3 img{
    width: 100%;
    border-radius: 10px;
    height: 100%;
    object-fit: fill;
  }
  
  .image_block_one .image-box .image-1:hover:before {
    -webkit-animation: shine 1s;
    animation: shine 1s;
  }
  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }
  @keyframes shine {
    100% {
      left: 125%;
    }
  }
  .image_block_one .image-box .image-2:hover:before,
  .image_block_one .image-box .image-3:hover:before {
    -webkit-animation: circle .95s;
    animation: circle .95s;
  }
  @-webkit-keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
  }
  @keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
  }
  .about-section .pattern-layer{
    position: absolute;
    left: -105px;
    top: -50px;
    width: 213px;
    height: 213px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 6s infinite linear;
    animation: zoom-fade 6s infinite linear;
  }
  