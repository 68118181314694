.not-found-container {
    text-align: center;
    padding: 50px;
  }
  
  .not-found-image {
    max-width: 50%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .not-found-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .back-button {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #2A3FA0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  