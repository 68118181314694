
/** banner-section **/

.banner-section{
  position: relative;
  overflow: hidden;  
}

.banner-section .pattern-layer{
  position: absolute;
  left: 0px;
  top: -100px;
  width: 100%;
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  background: linear-gradient(180deg,#eaf8ff,#f6f6ff);
}
  
.banner-carousel .slide-item{
  position: relative;
  padding: 65px 0px 152px 0px;
}


  
.banner-carousel .slide-item .bg-layer{
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transform:scale(1);
  -ms-transform:scale(1);
  transform:scale(1);
  -webkit-transition: all 8000ms linear;
  -moz-transition: all 8000ms linear;
  -ms-transition: all 8000ms linear;
  -o-transition: all 8000ms linear;
  transition: all 8000ms linear;
}
.banner-carousel .content-box .title-text{
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--theme-color);
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.banner-carousel .content-box h2{
  font-family: sans-serif;
  font-weight: 800;
  letter-spacing: -.025em;
  font-size: 5rem;
  line-height: 5rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background: -webkit-linear-gradient(left, #3d3d3a 25%,#ff9933 50%,#3D3D99);
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 4s;
  animation-name: textAnimted;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.banner-carousel .content-box p.ph4{
  font-size: 3.6rem;
  text-transform: uppercase;
  color: #666;
  font-family: sans-serif;
  height: auto;
  line-height: 3.6rem;
}

@keyframes aitf {
	0% { background-position: 0% 20%; }
  50% { background-position: 20% 40%; }
  75% { background-position: 40% 70%; }
	100% { background-position: 70% 100%; }
}
@keyframes textAnimted {
  0% {
      background-position: 0 1600px;
  }
  100% {
      background-position: 1600px 0;
  }
}

  
.banner-carousel .content-box p{
  font-size: 61.5px;
  line-height: 61px;
  margin-bottom: 19px;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  font-family: sans-serif;
}
 
.banner-carousel .image-box .image-shape-1{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 213px;
  height: 231px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.banner-section .shape .shape-1{
  position: absolute;
  left: 65px;
  top: -90px;
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}
.banner-section .shape .shape-2{
  position: absolute;
  left: 122px;
  bottom: 160px;
  width: 115px;
  height: 116px;
  background-repeat: no-repeat;
}
.banner-section .shape .shape-3{
  position: absolute;
  left: 44%;
  top: 50px;
  width: 112px;
  height: 112px;
  background-repeat: no-repeat;
}

