.contact-section{
    position: relative;
    padding: 70px 0 70px 0;
  }
  
  .contact-section .content-box{
    position: relative;
    display: block;
  }
  
  .contact-section .content-box .info-list li{
    position: relative;
    display: block;
    margin-bottom: 20px;
    padding-left: 34px;
  }
  
  .contact-section .content-box .info-list li:last-child{
    margin-bottom: 0px;
  }
  
  .contact-section .content-box .info-list li .icon{
    position: absolute;
    left: 0px;
    top: 2px;
    font-size: 20px;
    color: var(--theme-color);
  }
  
  .contact-section .content-box .info-list li:last-child .icon{
    font-size: 14px;
  }
  
  .contact-section .content-box .info-list li h4{
    display: block;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .contact-section .content-box .info-list li p{
    display: block;
    font-size: 16px;
    line-height: 26px;
  }
  
  .contact-section .content-box .info-list li p a{
    display: inline-block;
    color: var(--text-color);
  }
  
  .contact-section .content-box .info-list li p a:hover{
    text-decoration: underline;
  }
  
  .contact-section .form-inner{
    position: relative;
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 40px 60px;
  }
  
  
  .google-map-section{
    position: relative;
  }
  
  .google-map-section #contact-google-map{
    position: relative;
    width: 100%;
    height: 620px;
  }