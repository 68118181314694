.service-section{
    position: relative;
  }
  
  .service-block-one .inner-box{
    position: relative;
    display: block;
    background: #fff;
    padding: 30px 30px 0px 30px;
    box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 100px 100px;
  }
  
  .service-block-one .inner-box .image-box{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 10px 10px 100px 100px;
  }
  
  .service-block-one .inner-box .image-box img{
    width: 100%;
    border-radius: 10px 10px 100px 100px;
    transition: all 500ms ease;
  }
  
  .service-block-one .inner-box:hover .image-box img{
    transform: scale(1.05);
  }
  
  .service-block-one .inner-box .lower-content{
    position: relative;
    display: block;
    padding: 80px 10px 62px 10px;
  }
  .industries .service-block-one .inner-box .lower-content{
    padding: 30px 10px 60px 10px;
  }
  
  .service-block-one .inner-box .lower-content .icon-box{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    width: 127px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 50px;
    color: var(--theme-color);
  }
  
  .service-block-one .inner-box .lower-content .icon-box .icon-bg{
    position: absolute;
    left: -36px;
    top: -3px;
    width: 202px;
    height: 173px;
    background-repeat: no-repeat;
    z-index: -1;
  }
  
  .service-block-one .inner-box .lower-content .icon-box .icon{
    position: relative;
    display: inline-block;
    transition: all 500ms ease;
  }
  
  .service-block-one .inner-box:hover .lower-content .icon-box .icon{
    transform: rotateY(-180deg);
  }
  
  .service-block-one .inner-box .lower-content h3{
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  
  .service-block-one .inner-box .lower-content h3 a{
    display: inline-block;
    color: #101A30;
  }
  
  .service-block-one .inner-box .lower-content h3 a:hover{
    color: var(--theme-color);
  }
  
  .service-block-one .inner-box .lower-content p{
    color: #101A30;
  }
  
  .service-block-one .inner-box .lower-content .link{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -35px;
  }
  
  .service-block-one .inner-box .lower-content .link a{
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 74px;
    background: #fff;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    color: #101A30;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.15);
  }
  
  .service-block-one .inner-box .lower-content .link a:hover{
    color: #fff;
    background: var(--theme-color);
  }
  
  .service-section .pattern-layer{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }