/** process section **/
.sgpSec.sec-pad {
  padding: 50px 0px;
}
.sgpSec .titleSec{
  z-index: 28;
  position: relative;
}
.sgpSec .titleSec h3{
  margin-bottom: 20px;
}

.sgpSec ul.infoGraphic {
  font-size: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sgpSec ul.infoGraphic li {
  position: relative;
  width: 100%;
  max-width: 26em;
  background: var(--bg1);
  border-radius: 0.5em;
  padding: 0.5em;
  z-index: 1;
  transition: all 0.2s;
  cursor: pointer;
  margin-bottom: 2em;
}
.sgpSec ul.infoGraphic li .numberWrap {
  position: absolute;
}
.sgpSec ul.infoGraphic li .number {
  font-family: "maven pro", sans-serif;
  font-size: 7em;
  font-weight: 900;
  width: 2.3em;
  text-align: center;
}
.sgpSec ul.infoGraphic li .number.fontColor1 {
  color: var(--blue);
}
.sgpSec ul.infoGraphic li .number.fontColor2 {
  color: var(--green);
}
.sgpSec ul.infoGraphic li .number.fontColor3 {
  color: var(--purple);
}
.sgpSec ul.infoGraphic li .number.fontColor4 {
  color: var(--gold);
}
.sgpSec ul.infoGraphic li .number.fontColor5 {
  color: var(--red);
}
.sgpSec ul.infoGraphic li .number.fontColor6 {
  color: var(--orange);
}
.sgpSec ul.infoGraphic li .number.fontColor7 {
  color: #e0876a;
}
.sgpSec ul.infoGraphic li .number.fontColor8 {
  color: #e06377;
}
.sgpSec ul.infoGraphic li .number.fontColor9 {
  color: 526D82;
}
.sgpSec ul.infoGraphic li .coverWrap {
  transform: rotate(130deg);
  position: absolute;
  width: 18em;
  height: 15em;
  left: -3em;
  top: -4em;
}
.sgpSec ul.infoGraphic li .coverWrap .numberCover {
  position: absolute;
  background: var(--bg1);
  width: 18em;
  height: 6em;
  border-radius: 50% 50% 0 0;
  border-bottom: 3px solid #f5f8f7;
  transition: all 0.4s;
}
.sgpSec ul.infoGraphic li .coverWrap .numberCover::before {
  position: absolute;
  content: "";
  bottom: 5px;
  left: 4em;
  right: 4em;
  top: 5em;
  box-shadow: 0 0 30px 17px #48668577;
  border-radius: 100px/10px;
  z-index: -1;
}
.sgpSec ul.infoGraphic li .coverWrap .numberCover::after {
  position: absolute;
  bottom: 0;
  content: "";
  left: -10%;
  width: 120%;
  height: 150%;
  background: radial-gradient(at bottom, #48668533, transparent, transparent);
  z-index: 1;
}
.sgpSec ul.infoGraphic li .content {
  margin: 5em 0em 0em 6.5em;
  position: relative;
}
.sgpSec ul.infoGraphic li .content h2 {
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.sgpSec ul.infoGraphic li.active h2{
  color: var(--red);
  font-weight: 700;
  font-size: 1em;
} 
.sgpSec ul.infoGraphic li .content p {
  line-height: 1.5em;
  text-align: center;
}
.sgpSec ul.infoGraphic li:hover .coverWrap .numberCover {
  border-radius: 100%;
}
.sgpSec .icon {
  position: absolute;
  font-size: 2rem;
  text-align: center;
  top: -1.3em;
left: 50%;
  transform: translatex(-33%);
  width: 40px;
}
.sgpSec .icon img{
  filter: drop-shadow(2px 2px 1px #4D4345);
}
/* end process */



/** start - modal **/
.process-popup {
  position: fixed;
  left: 0;
  top: 32%;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}
.process-popup.popup-visible {
  visibility: visible;
  opacity: 1;
}
.process-popup .close-search {
  top: -2px;
  right: 0px;
  background-color: var(--themeColor);
  color: #ccc;
  padding: 2px 10px;
  border-top-right-radius: 30%;
  border-bottom-left-radius: 15px;
  position: absolute;
  transition: all 500ms ease;
  cursor: pointer;
  z-index: 5;
  font-size: 25px;
}
.process-popup .close-search:hover {
  color: #ccc;
  transform: rotate(180deg);
  border-top-right-radius: 50%;
  border-bottom-left-radius: 25%;
}
.process-popup .upper-box {
  position: relative;
  padding: 0px 0px;
  z-index: 99;
}