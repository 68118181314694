
/** materials-section **/

.materials-section{
  position: relative;
}

.materials-section .inner-container{
  position: relative;
  display: block;
  padding: 63px 100px 67px 100px;
  background: var(--theme-color);
  border-radius: 20px;
  z-index: 1;
  margin-top: -205px;
  overflow: hidden;
}

.materials-section .list-style-one li{
  color: #fff;
  line-height: 28px;
  margin-bottom: 15px;
}

.materials-section .list-style-one li:last-child{
  margin-bottom: 0px;
}

.materials-section .list-style-one li:before{
  background: rgba(255, 255, 255, 0.2) !important;
}

.materials-section .inner-container .shape .shape-1{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.materials-section .inner-container .shape .shape-2{
  position: absolute;
  left: 47%;
  top: 34%;
  width: 113px;
  height: 108px;
  background-repeat: no-repeat;
}

.materials-section.alternat-2 .inner-container{
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
  border-radius: 0px;
}












































