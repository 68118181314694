/* auctionListing */
.auctionListing{
    padding:50px 0;
  }

  .auctionListing .titleSec.mb_40{
    margin-bottom: 40px;
  }

.auctionListing .mb_70 {
  margin-bottom: 40px;
}
  .auctionListing .auction-block{
    transition: all .2s ease;
    box-shadow: 0 5px 10px rgba(0,0,0,.12);
    height:100%;
    width:100%;
    border-radius: 8px;
  }
  .auctionListing .auction-block:hover{
    box-shadow: 0 30px 60px rgba(0,0,0,0.12);
  }
  .auctionListing .auction-block .inner-box .image-box {
    border-radius: 8px 0px 8px 0px;
    background-image: radial-gradient(#eaeaea 1px,#c88c8c00 1px);
    background-position: 50%;
    background-size: 25px 25px;
    width: 100%;
    height: 170px;
  }
  .auctionListing .auction-block .inner-box .image-box img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .auctionListing .auction-block .inner-box .lower-content .block-2 {
    background: #f7fafc;
    border-radius: 8px;
  }


  .myBtn{
    width:179px;
    padding-left: 0;
    padding-right: 0;
  }  
  .theme-btn.btn-two.myBtn span {
    padding: 10px 0px;
  }
  .theme-btn.myBtn span::before {
    width: 15px;
    height: 15px;
  }
  .theme-btn.myBtn span::after {
    width: 15px;
    height: 15px;
  }
  