.sl-benefitSec{
    padding: 50px 0;
    /* background-color: #f9f9ff; */
    background-color: #fff;
}
.sl-benefitInner{
    position: relative;
    padding-top: 20px;
}

.sl-benefitSec .shape-images{
    position: absolute;
    right: 10%;
    top: 0;
    max-width: 36%;
    z-index: -1;
}

.sl-benefitSec .seller{
    margin-bottom: 12px;
}
.sl-benefitSec .seller,
.sl-benefitSec .dealer{
    overflow: hidden;
}
.sl-benefitSec .seller p,
.sl-benefitSec .dealer p{
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 8px;
    transition: .5sec ease-in ;
    color:#757589;;
}

.sl-benefitSec .sl{
    height:80px;
}
.sl-benefitSec .sellerImgBox{
    position: relative;
    width: 100%;
    height:100%;
}
.sl-benefitSec .sellerImg{
    position: absolute;
    /* clip: rect(0, 200px, 200px, 0); */
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.seller, .dealer{
    position: relative;
  z-index: 1;
  border: 1px solid #e6e3f1;
  border-radius: 5px;
  padding: 10px;
}