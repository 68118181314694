.about-style-two{
    position: relative;
    padding-top: 60px;
    padding-bottom: 120px;
  }
  
  .image_block_three .image-box{
    position: relative;
    display: block;
  }
  
  .image_block_three .image-box .image-1{
    position: relative;
    display: block;
    border-radius: 20px;
    overflow: hidden;
  }
  
  .image_block_three .image-box .image-1 img{
    width: 100%;
    border-radius: 20px;
  }
  
  .image_block_three .image-box .image-1:before{
    position: absolute;
    top: 0;
    left: -85%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
    z-index: 1;
  }
  
  .image_block_three .image-box .image-1:hover:before {
    -webkit-animation: shine 1s;
    animation: shine 1s;
  }
  
  .image_block_three .image-box .image-2{
    position: absolute;
    right: 0px;
    bottom: -80px;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 8px solid #fff;
    box-shadow: 0px 20px 100px rgba(6, 49, 55, 0.2);
    overflow: hidden;
    z-index: 1;
  }
  
  .image_block_three .image-box .image-2 img{
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
  }
  
  .image_block_three .image-box .image-2:before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  
  .image_block_three .image-box .image-2:hover:before {
    -webkit-animation: circle .95s;
    animation: circle .95s;
  }
  
  .image_block_three .image-box .video-box{
    position: absolute;
    left: -60px;
    top: 0px;
    width: 180px;
    background: var(--theme-color);
    border-radius: 20px;
    padding: 45px;
    z-index: 1;
  }
  
  .image_block_three .image-box .video-box a{
    position: relative;
    display: inline-block;
    width: 90px;
    height: 90px;
    line-height: 90px;
    border: 5px solid #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 36px;
    color: #fff;
  }
  
  .image_block_three .image-box .video-box a:before,
  .image_block_three .image-box .video-box a:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -webkit-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  
  .image_block_three .image-box .video-box a:after{
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
  }
  
  .image_block_three .image-box .image-shape{
    position: absolute;
    right: 52px;
    bottom: -53px;
    width: 180px;
    height: 180px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 4s infinite linear;
    animation: zoom-fade 4s infinite linear;
  }
  
  .content_block_three .content-box{
    position: relative;
    display: block;
  }
  
  .content_block_three .content-box .theme-btn span{
    padding: 18px 40px;
  }