.pro-sidebar {
    z-index: 10!important;
  }
.modal-header {
  height: 40px;
}
  .modal-header .btn-close {
    background-color: #fff;
    width: 10px;
    height: 10px;
    font-size: 10px;
  }
.modal-title h4 {
  font-size: 20px;
}
.card-header{
  background-color: #2A3FA0!important;
  color: #fff!important;
  height: 40px!important;
}
.modal-title{
  color: #602525!important;
}
.sidebar {
  background-color: #4361ee;
}
.ivErMX {
  width: 100% !important;
  min-width: 100% !important;
  color: #fff !important;
  background-color: #2a3fa0 !important;
}
.ftyVLO.toggled {
  width: 80px !important;
  min-width: 80px !important;
}
.kmTLRE .active{
  background-color: #fff;
}
// .ivErMX {
//   width: inherit !important;
//   min-width: inherit !important;
// }
.cwlUUQ {
  background: #2B3FA1 !important;
  color: #FFF !important;
  width: inherit !important;
  min-width: inherit !important;
}
.iMbSSf {
  margin: 0px 0px !important;
  border-bottom: 1px solid #ccc;
}
.hQSxZz {
  list-style: none !important;
  margin: 10px 0px !important;
  // background: #ccf1f7 !important;
  border-bottom: 1px solid #ccc;
  // border-right: 3px solid #fff !important;
}
.hQSxZz:hover{
  // background: #1baac1 !important;
  border-right: 3px solid #dddddd !important;
}
// .iMbSSf {
//   list-style: none !important;
//   margin: 10px 0px !important;
//   background: #ccf1f7 !important;
//   border-right: 3px solid #000 !important;
// }
// .iMbSSf:hover{
//   background: #1baac1 !important;
//   border-right: 3px solid #7b0101 !important;
// }
.gDdBnU a {
  color: #fff !important;
  text-decoration: none !important;
}
.gDdBnU a:hover{
  color: #fff !important;
  text-decoration: none !important;
}
.fuMoWm a {
  color: #fff !important;
  text-decoration: none !important;
}
.fuMoWm a:hover{
  color: #fff !important;
  text-decoration: none !important;
}
.head-div{
  padding: 17px 10px  !important;
  background:#5c79ff !important;
  padding-left: 30px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.jAaeFT{
  padding-top: 0 !important;
}
.fRhOkl{
  padding-top: 0 !important;
}
.tophd{
    // background-color: #ede7c9;
    // padding: 5px 15px;
    margin: 0px 0px 10px 0px;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    line-height: 55px;
    display: block;
    border-bottom: 1px solid #000;
    // width: 30%;
}
.tophd a{
  color: #2A3FA0;
  font-weight: 500;
  display: contents;
  text-transform: capitalize;

}
.bell-container{
  padding: 3px 8px;
}
.button__badge{
  background-color: #fa3e3e;
    border-radius: 50px;
    color: white;
    padding: 0px 0px;
    font-size: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bell-all{
  text-align: center;
  padding-top: 10px;
  font-size: 15px;
  font-weight: bold;
}
.footer-bottom{
  background-color:#5c79ff !important;
  color: #fff;
}
