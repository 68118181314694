.auctionPageSec{
    padding: 70px 0 70px 0;
}

.auctionPage ul.listingView li button {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
  }
  .auctionPage ul.listingView li.active button {
    background-color: var(--theme-color);
    color: #fff;
  }
  .auctionPage .image-box img{
    width: 300px;
    height: 170px;
  } 
  .auctionPage .block-1 a.theme-button {
    height: auto;
    line-height: 8px;
    padding: 12px 5px;
    font-size: 15px;
    font-weight: 600;
    width: 84px;
  }
  .auctionPage .lower-content .block-2 p{
    font-size: 15px;
  }
  .auctionPage .listView .inner-box .image-box{
    width:40%;
    float:left;
  }
  .auctionPage .listView .inner-box .lower-content{
    width: 60%;
  float: right;
  border-top: 0 !important;
  height: 100%;
  background: #f7fafc;
  border-left: 1px solid #edf2f7;
  }
  .auctionPage .listView .inner-box .lower-content .block-1{
    background-color: #fff;
  }
  .auctionPage .listView .inner-box .lower-content .block-2{
  border-radius: 0;
  }
  .auctionPage .auctionBid .form-group input[type="text"] {
    width: 75px;
    height: 25px;
    font-size: 13px;
    padding: 0px 4px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0;
  }
  .auctionPage .block-2 .auctionBid .form-group .theme-button {
    height: auto;
  line-height: 8px;
  padding: 0px 4px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  border:1px solid #dee2e6;
  border-left:0;
  }
  .auctionPage .block-2 .auctionBid .form-group .theme-button::before {
    border: none;
  }

  .auctionPage .blog-sidebar .search-widget .search-form .form-group input[type="search"] {
    height: 40px;
  }
  .blog-sidebar .search-widget .search-form .form-group button[type="submit"] {
    top: 8px !important;
  }
  .theme-button::before {
    border-radius: 4px;
  }
  .default-sidebar .widget-title, .widget-title {
    padding: 17px 0;
  }