/** cta-style-two **/

  .cta-style-two{
    position: relative;
    padding: 60px 0px 60px 0px;
    background: linear-gradient(180deg,#fef7e2,#eeeefd);
    overflow: hidden;
  }
  .cta-style-two .pattern-layer .pattern-2{
    position: absolute;
    left: -72px;
    bottom: 30px;
    width: 148px;
    height: 148px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 4s infinite linear;
    animation: zoom-fade 4s infinite linear;
  }
  .cta-style-two .pattern-layer .pattern-3{
    position: absolute;
    right: -72px;
    top: 30px;
    width: 148px;
    height: 148px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 4s infinite linear;
    animation: zoom-fade 4s infinite linear;
  }
  .cta-style-two .pattern-layer .pattern-4{
    position: absolute;
    left: 130px;
    top: 120px;
    width: 113px;
    height: 108px;
    background-repeat: no-repeat;
  }
  .cta-style-two .pattern-layer .pattern-5{
    position: absolute;
    right: 110px;
    bottom: 80px;
    width: 115px;
    height: 115px;
    background-repeat: no-repeat;
  }
  .cta-style-two .content-box{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .cta-style-two .content-box h2{
    display: block;
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 50px;
    background-image: linear-gradient( -225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100% );
  background-clip: border-box;
  text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .cta-style-two .content-box .btn-box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cta-style-two .content-box .btn-box .theme-btn.btn-one{
    margin-right: 10px;
  }
  .cta-style-two .content-box .btn-box .theme-btn{
    margin-top: 1em;
  }
  .cta-style-two .content-box .btn-box .theme-btn span{
    padding: 10px 13px;
  }
  .cta-style-two .pattern-layer .pattern-1{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  

  .cta-style-two .theme-btn{
    width:160px;
  }