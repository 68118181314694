/*** upcoming auction ***/
.auctionDetails,.upcAuctionPage{
    padding: 70px 0 70px 0;
}
.upcAuction .default-sidebar .sidebar-widget {
    position: relative;
    display: block;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 6px;
    background-color: inherit;
  }
  
  .upcAuction .dropdown .btn-primary {
    background-color: var(--themeColor);
    border: 1px solid var(--themeColor);
    width: 210px;
    text-align: start;
  }
  .upcAuction .dropdown .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
  }
  .upcAuction .filterSec {
    border: 1px solid var(--themeColor);
    padding: 10px;
  }
  .upcAuction .dropdown .dropdown-item {
    border-radius: 0.375rem;
  }
  .upcAuction .dropdown .dropdown-item:hover,
  .dropdown-item:focus {
    color: #fff;
    background-color: var(--themeColor);
  }
  .upcAuction .filterSec .theme-button {
    height: 38px;
    line-height: 35px;
    padding: 0 18px;
  }
  .upcAuction .table {
    background-color: #fefefe;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
    border: 1px solid var(--themeColor);
  }
  .upcAuction table th {
    text-align: inherit;
    text-align: -webkit-match-parent;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    vertical-align: middle;
    background-color: var(--themeColor);
    color: #fff;
    border-right: 1px solid #ccc;
  }
  