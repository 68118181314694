.filter-tab {
  border: 0 none;
  text-transform: capitalize;
  background: #ffffff;
  color: #6b7385;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.41);
  border-radius: 500px;
  font-size: 18px;
  letter-spacing: -0.6px;
  transition: 0.4s;
  position: relative;
  height: 70px;
}

.filter-tab .cntNum {
  position: absolute;
  display: inline-block;
  right: 20px;
  font-size: 14px;
  top: 8px;
  line-height: initial;
  opacity: 0.5;
}

.cmpInnerBox {
  position: relative;
}

.cmpInnerBox .nav .li.nav-item a.nav-link span {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(21, 87, 153, .08);
  padding: 12px 12px 12px;
  border-radius: 25px;
  position: relative;
  display: block;
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
  /* background: linear-gradient(to right, #FCBE31 50%, white 50%); */
  background: linear-gradient(to right, var(--secondary-color) 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
  position: relative;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  text-align: start;
  padding-left: 1.5rem;
}

.cmpInnerBox .nav .li.nav-item a.nav-link span:hover {
  background-position: left bottom;
  color: #fff
}

.cmpInnerBox .nav .li.nav-item a.nav-link span:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -1px;
  width: 7px;
  height: 166px;
  /* background: linear-gradient(to right, #FCBE31 100%, #FCBE31 100%); */
  background: linear-gradient(to right, var(--secondary-color) 100%, var(--secondary-color) 100%);
  
}

.compliancePage .flterBtn .nav-pills .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 0.5s all ease-in-out;
}

.compliancePage .flterBtn .nav-pills .nav-link.active,
.compliancePage .cmpInnerBox .nav-pills .nav-link.active {
  color: var(--themeColor);
  background-color: transparent;
}
.compliancePage .flterBtn .nav-pills .nav-link:hover{
  color:var(--secondary-color);
}

.compliancePage .flterBtn .nav-pills .nav-link.active{
  background-color: var(--secondary-color);
  border-radius: 500px;
  color:#fff
}
.compliancePage .flterBtn .nav-pills .nav-link.active .cntNum{
  color:#fff;
  opacity: 1;
}

.compliancePage .cmpSidebar {
  border: 1px solid #fafafa;
  position: sticky;
  top: 0rem;
  overflow: hidden;
}
.compliancePage .cmpSidebar .widget-title {
  position: relative;
  display: block;
  padding: 20px 0;
  background-color: #ededed;
  border-radius: 6px;
}

.compliancePage .nav-link {
  color: #000;
}
.compliancePage .innerBlock{
  background: #fff;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.41);
  padding: 26px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}



/** start compliance page **
.compliancePage .wasteList .nav-pills .nav-link.active,
.compliancePage .wasteList button.headerSec {
  background: #eee;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .03), 0 2px 4px rgba(0, 0, 0, .05), 0 12px 24px rgba(0, 0, 0, .05);
  padding: 4px 12px;
  border-radius: 8px;
  display: flex;
  margin-bottom: 10px;
  width:100%;
}
.compliancePage .wasteList .nav-pills .nav-link.active::before,
.compliancePage .wasteList button.headerSec::before {
  content: "";
  position: absolute;
  inset: -1px;
  border-radius: inherit;
  padding: 1px;
  background: radial-gradient(75% 75% at 25% 15%, #fff 0, hsla(0, 0%, 100%, .3) 100%);
  -webkit-mask: linear-gradient(#000, #000) content-box, linear-gradient(#000, #000);
  mask: linear-gradient(#000, #000) content-box, linear-gradient(#000, #000);
  mask-composite: add, add;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: .5;
  pointer-events: none;
  background: radial-gradient(75% 75% at 25% 15%, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .2) 100%);
}
.compliancePage .wasteList button.headerSec i.far {
  font-size: 18px;
  line-height: 26px;
  padding-right: 10px;
  color: var(--themeColor);
}
.compliancePage .wasteList button.headerSec h4 {
  font-size: 20px;
}



.compliancePage .wasteList .accordionBody .accordionInnerHeader .accordion-button {
  background-color: var(--secondary-color);
  padding: 3px 12px;
  border-radius: 8px;
  text-align: start;
  color: #fff;
  overflow: hidden;
}

.compliancePage .wasteList .accordion-body .accordionInnerHeader .accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: var(--secondary-color) !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.compliancePage .wasteList .accordion-body .accordionInnerItem :last-child .accordion-button{
  margin-bottom: 0;
  display: block;
}
.compliancePage .wasteList .accordionBody {
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.compliancePage .wasteList .accordionBody .accordionInnerItem{
  background: inherit;
  border-bottom: 0;
  margin-bottom: 0.8em;
}
.compliancePage .wasteList .accordionBody .accordionInnerItem:last-of-type{
  margin-bottom: 0;
}
.compliancePage .wasteList .accordionBody .accordionInnerItem .accordionInnerBody{
  background-color: var(--themeColor);
  color:#fff;
  border-radius: 12px;
  margin-bottom: 0.5em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 12px;
}
.compliancePage .wasteList .accordion-body .subHeaderSec .accordion-title{
  background-color: var(--secondary-color);
padding: 4px 12px;
border-radius: 8px;
color: #fff;
font-size: 18px;
box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
}
.compliancePage .accordionInnerItem  {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
.compliancePage .accordionInnerHeader{
  background: inherit;
}
.compliancePage .accordionInnerHeader .accordion-button {
  padding: 0px 0px 17px 0px;
  background-color: inherit;
}
.compliancePage .accordionInnerHeader .accordion-button::after {
  display: none;
}


.accordionSec .accordionItem{
  margin-bottom: 12px;
}
.accordion-button {
  padding: 5px 12px !important;
background-color: var(--secondary-color) !important;
color: #fff !important;
}
.compliancePage .accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: var(--secondary-color) !important;
  box-shadow: none;
}
/** end compliance **/



/* CSS for the sticky element */
/* .sticky-element {
  padding: 20px;
  border: 1px solid #ddd;
}
.sticky2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
} */
