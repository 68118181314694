
.header-lower{
  border-bottom: 1px solid #000248;
}
.box-1{
    background: rgb(186, 155, 252) !important;
}
.box-2{
    background: rgb(251, 198, 0) !important;
}
.box-3{
    background: rgb(0, 182, 254) !important;
}
.box-4{
    background: #37c8ed !important;
}
.box-5{
    background: #f5a08b !important;
}
.box-6{
    background: #f59cdc !important;
}
.counter{
    font-size: 15px;
    font-weight: 600;
    span{
        color: var(--theme-color);
    }
}
.expired {
    color: red;
}
.eauctionsectitle{
    color: #0d056c;
    font-size: 15px;
    font-weight: 600;
    height: 40px;
}
.eauctionsec li{
    background-color: #e4f6f9;
    font-size: 15px;
    color: #090b74;
    margin-bottom: 5px;
    padding: 1px 11px;
    span{
        color: #890808;
        padding-right: 15px;
        font-weight: 600;  
    }
}

.tracking-detail {
    padding: 3rem 0;
  }
  #tracking {
    margin-bottom: 1rem;
  }
  [class*="tracking-status-"] p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }
  [class*="tracking-status-"] {
    padding: 1.6rem 0;
  }
  .tracking-list {
    border: 1px solid #e5e5e5;
  }
  .tracking-item {
    border-left: 4px solid #00ba0d;
    position: relative;
    padding: 2rem 1.5rem 0.5rem 2.5rem;
    font-size: 0.9rem;
    margin-left: 3rem;
    min-height: 5rem;
  }
  .tracking-item:last-child {
    padding-bottom: 4rem;
  }
  .tracking-item .tracking-date {
    margin-bottom: 0.5rem;
  }
  .tracking-item .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
  }
  .tracking-item .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
  }
  .tracking-item .tracking-content span {
    display: block;
    color: #767676;
    font-size: 13px;
  }
  .tracking-item .tracking-icon {
    position: absolute;
    left: -0.7rem;
    width: 1.1rem;
    height: 1.1rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    background-color: #fff;
    color: #fff;
  }
  
  .tracking-item-pending {
    border-left: 4px solid #d6d6d6;
    position: relative;
    padding: 2rem 1.5rem 0.5rem 2.5rem;
    font-size: 0.9rem;
    margin-left: 3rem;
    min-height: 5rem;
  }
  .tracking-item-pending:last-child {
    padding-bottom: 4rem;
  }
  .tracking-item-pending .tracking-date {
    margin-bottom: 0.5rem;
  }
  .tracking-item-pending .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
  }
  .tracking-item-pending .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
  }
  .tracking-item-pending .tracking-content span {
    display: block;
    color: #767676;
    font-size: 13px;
  }
  .tracking-item-pending .tracking-icon {
    line-height: 2.6rem;
    position: absolute;
    left: -0.7rem;
    width: 1.1rem;
    height: 1.1rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    color: #d6d6d6;
  }
  .tracking-item-pending .tracking-content {
    font-weight: 600;
    font-size: 17px;
  }
  
  .tracking-item .tracking-icon.status-current {
    width: 1.9rem;
    height: 1.9rem;
    left: -1.1rem;
  }
  .tracking-item .tracking-icon.status-intransit {
    color: #00ba0d;
    font-size: 0.6rem;
  }
  .tracking-item .tracking-icon.status-current {
    color: #00ba0d;
    font-size: 0.6rem;
  }
  @media (min-width: 992px) {
    .tracking-item {
      margin-left: 10rem;
    }
    .tracking-item .tracking-date {
      position: absolute;
      left: -10rem;
      width: 7.5rem;
      text-align: right;
    }
    .tracking-item .tracking-date span {
      display: block;
    }
    .tracking-item .tracking-content {
      padding: 0;
      background-color: transparent;
    }
  
    .tracking-item-pending {
      margin-left: 10rem;
    }
    .tracking-item-pending .tracking-date {
      position: absolute;
      left: -10rem;
      width: 7.5rem;
      text-align: right;
    }
    .tracking-item-pending .tracking-date span {
      display: block;
    }
    .tracking-item-pending .tracking-content {
      padding: 0;
      background-color: transparent;
    }
  }
  
  .tracking-item .tracking-content {
    font-weight: 600;
    font-size: 17px;
  }
  
  .blinker {
    border: 7px solid #e9f8ea;
    animation: blink 1s;
    animation-iteration-count: infinite;
  }
  @keyframes blink { 50% { border-color:#fff ; }  }  
  .ant-steps-item-title{
    font-size: 12px !important;
    font-weight: 600;
    color: #2022c5 !important;
  }
  .ant-steps-item-title::after {
    background-color: #d7d7d7 !important;
  }
  .addnew{
    --bs-btn-padding-y: 2px !important;
    --bs-btn-padding-x: 8px !important;
    font-size: 13px !important;
  }
  thead, tbody tr {
            
    display:table;
    width:100%;
    table-layout:fixed;
}

tr th, tr td {           
   font-size: 11px;
   font-size: 13px;
   line-height: 20px;
   height: 20px;
}
tr th, tr td .btn {
  font-size: 12px;
}
.pagination {
  height: 20px;
}
.pagination .page-item a{
  padding: 0px 9px;
  font-size: 15px;
}
.pagination .page-item a:hover{
  color: #fff;
}

.dropdown-menu{
  margin-top: 9px !important;
  min-width: 185px !important;
  background-color: #ede7c9 !important;
}
.userBtn .dropdown-item {
  font-size: 15px !important;
}
.page-link.active, .active > .page-link {
  background-color: #7e7889 !important;
  border-color: #7e7889 !important;
}
.bg-green {
  background-color: #ede7c9;
}
.modal-body{
  background-color: #fff;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.card-body-title{
  padding: 5px 15px !important;
  flex: none !important;
}
.card-title{
  font-size: 18px !important;
}
.list-group-item{
  font-size: 14px;
  line-height: 17px;
}


.form-control {
  position: relative;
  width: 100%;
  height: 2.75rem;
  margin-bottom: 1.5rem;
  // margin-bottom: 5px;
}
.form-label {
  position: absolute;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  left: 1rem;
  top: 0.75rem;
  padding: 0 0.25rem;
  color: #000248;
  background: #fff;
  transition: all 0.3s ease;
  width: fit-content;
}
.form-input {
  position: absolute;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  top: 0;
  left: 0;
  z-index: 1;
  resize: none;
  width: 100%;
  height: auto;
  padding: 0.52rem 1.25rem;
  border-radius: 0.25rem;
  // border: 0.5px solid #000248;
  color: var(--color-black);
  // background: #e4f6f8;
  transition: all 0.2s ease-in-out;
}
.form-input2 {
  position: absolute;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  top: 0;
  left: 0;
  z-index: 1;
  resize: none;
  width: 100%;
  height: auto;
  padding: 0.52rem 1.25rem;
  border-radius: 0.25rem;
  outline: none;
  // border: 2px solid #000248;
  color: var(--color-black);
  // background: #e4f6f8;
  transition: all 0.2s ease-in-out;
}
.form-input::-moz-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}
.form-input:-ms-input-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}
.form-input::placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}
.form-input:focus {
  outline: none;
  border: 0.5px solid #2A3FA0;
}
.form-input:focus ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 5;
  font-size: 0.875rem;
  font-weight: 500;
  color: #2A3FA0;
  transition: all 0.2s ease-in-out;
}
.form-input:not(:-moz-placeholder-shown).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-input:not(:-ms-input-placeholder).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-input:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.form-input2:focus {
  outline: none;
  // border: 2px solid blue;
}
.form-input2:focus ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 5;
  font-size: 0.875rem;
  font-weight: 500;
  color: blue;
  transition: all 0.2s ease-in-out;
}
.form-input2:not(:-moz-placeholder-shown).form-input2:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-input2:not(:-ms-input-placeholder).form-input2:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-input2:not(:placeholder-shown).form-input2:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.dashboard-wrapper {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.sidebar-wrapper {
  background-color: #2A3FA0;
  min-height: calc(100vh - 56px); // Adjust based on your Topbar height
}

.main-content {
  padding: 2rem;
}

.page-title {
  color: #2A3FA0;
  font-weight: bold;
}

.dashboard-card {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .card-body {
    padding: 1.5rem;
  }

  h6 {
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  h3 {
    color: #2A3FA0;
    font-weight: bold;
  }

  .icon-shape {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
  }
}