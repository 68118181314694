/******** scrapListingd  ********/
.scrapListingd {
    padding: 70px 0 70px 0;
}

.scrapListingd .news-block-two {
    background: #FFFFFF;
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 22px;
}

.scrapListingd .news-block-two .inner-box .lower-content {
    padding-top: 0px;
}

.scrapListingd .avalable {
    padding: 2px 5px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 13px;
    border-bottom-right-radius: 10px;
}

.scrapListingd .news-block-two .inner-box {
    display: flex;
}

.scrapListingd .lower-content {
    margin-top: 8px;
}

.scrapListingd .blog-sidebar .search-widget .search-form .form-group button[type="submit"]{
    top:19px !important;
}



/** price filter **/
.priceBlk {
    display: flex;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
}

.priceBlk .priceBlk1,
.priceBlk .priceBlk2 {
    display: grid;
    width: 50%;
    padding: 0 10px;
}

.priceBlk1 label,
.priceBlk2 label {
    color: #101A30;
}

.priceBlk .input-group {
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    color: #575757;
    cursor: text;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-transition: border-color .15s ease-in-out;
    transition: border-color .15s ease-in-out;
    width: 100%;
}

.priceBlk input {
    background-color: #fff;
    color: #575757;
    cursor: text;
    display: flex;
    position: relative;
    -webkit-transition: border-color .15s ease-in-out;
    transition: border-color .15s ease-in-out;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 15px;
}

.priceSec p {
    color: var(--theme-color);
    text-align: center;
}