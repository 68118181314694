.solution-section .indList{
    font-size: 18px;
    color: #666;
    background: #fafafa;
    border: 1px solid #eaeaea;
    padding: 6px 15px 6px 15px;
    border-radius: 12px;
    margin-bottom:7px;
    transition: all 500ms ease;
    text-align: center;
    display: grid;
    place-items: center;
    height: 82px;
}
.solution-section .indList.ind1{
  border-bottom: 3px solid var(--blue);
}
.solution-section .indList.ind2{
  border-bottom: 3px solid var(--green);
}
.solution-section .indList.ind3{
  border-bottom: 3px solid var(--purple);
}
.solution-section .indList.ind4{
  border-bottom: 3px solid var(--gold);
}
.solution-section .indList.ind5{
  border-bottom: 3px solid var(--red);
}
.solution-section .indList.ind6{
  border-bottom: 3px solid var(--orange);
}
.solution-section.sec-pad {
  padding: 50px 0px;
}
.solution-section .indList:hover{
  background-color:#fff;
}
.solution-section .solutionImg img{
  height:420px;
}