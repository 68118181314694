.servicesBox {
  background-color: #f6f6ff;
  padding: 35px 0;
}
.servicesBox .srvSidebar .nav-pills .li.nav-item {
  margin-bottom: 6px;
}

.servicesBox .srvSidebar .nav-pills .nav-link {
  color: #000;
  background-color: #fff;
  box-shadow: 0 15px 34px 0 rgba(175, 181, 204, 0.32);
  border-radius: 6px;
  text-align: start;
  font-weight: bold;
  height: 50px;
  line-height: 35px;
  display: grid;
  place-items: center;
  place-content: start;
}

.servicesBox .srvSidebar .nav-pills .nav-link.active {
  background: linear-gradient(218.15deg, #b9667e 0%, #2f57ef 100%);
  opacity: 0.5;
  color: #fff
}

.servicesBox .srvInnerBlock {
  background: #fff;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.41);
  padding: 26px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.servicesBox .srvInnerBlock .inner {
  display: flex;
  align-items: center;
  position: relative;
}

.servicesBox .srvInnerBlock .inner .thumb img {
  border-radius: 6px;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.servicesBox .srvInnerBlock .inner .content {
  text-align: justify;
  padding-left: 30px;
}

.servicesBox .srvInnerBlock .inner .top-circle-shape {
  position: absolute;
  width: 240px;
  top: -96px;
  height: 240px;
  right: -96px;
  margin: 0 auto;
  background-image: linear-gradient(90deg, #CFA2E8, #637FEA);
  opacity: 0.09;
  border-radius: 100%;
  box-sizing: border-box;
}

.servicesBox .srvInnerBlock .inner .top-circle-shape::before {
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 100%;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  left: 30px;
  top: 30px;
}




