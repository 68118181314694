
/* accordian */
.accordion-item {
    position: relative;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0,0,0,.12);
    border-radius: 10px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  .accordion-header {
    position: relative;
    display: block;
  
    background: #fff;
    cursor: pointer;
    transition: all 500ms ease;
  }
  
  .accordion-button {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    transition: all 500ms ease;
    border-radius: 0;
    padding: 0;
    padding: 17px 60px 17px 30px;
  }
  
  .accordion-button:not(.collapsed) {
    color: #fff !important;
    box-shadow: none !important;
    background: var(--theme-color) !important;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  }
  
  .accordion-button:after {
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
  }
  .faq-form-section{
    position: relative;
  }
  
  .faq-form-section .pattern-layer{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .faq-form-section .inner-container{
    position: relative;
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
  }
  
  .faq-form-section .form-inner{
    position: relative;
    display: block;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 70px;
  }