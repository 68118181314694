/* Responsive Css */


@media only screen and (max-width: 1749px){



}


@media only screen and (max-width: 1499px){

  .main-header .menu-right-content .support-box{
    display: none;
  }

  .main-header .outer-box{
    padding: 0px 30px;
  }

  .header-top{
    padding-left: 30px;
    padding-right: 30px;
  }

}


@media only screen and (max-width: 1399px){

  .header-top .top-inner{
    display: flex;
    text-align: center;
  }

  .header-top .info-list li{
    float: none;
  }

  .header-top .top-right{
    justify-content: center;
  }

  .header-top .top-right .login-box:before{
    display: none;
  }

  .header-top .top-right .login-box{
    padding-right: 0px;
  }

}


@media only screen and (max-width: 1299px){

  .feature-block-one .inner-box .shape{
    display: none;
  }

  .feature-block-one .inner-box{
    background: #fff;
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
  }

  .funfact-block-one .inner-box:before{
    display: none;
  }

}



@media only screen and (max-width: 1200px){

  .main-menu,
  .sticky-header,
  .main-header.style-one .outer-container:before{
    display: none !important;
  }

  .menu-area .mobile-nav-toggler {
    display: block;
    padding: 10px;
  }

  .megamenu ul li:first-child{
    display: none;
  }

  .banner-section .banner-carousel .owl-nav{
    display: none;
  }

  .materials-section .list-inner{
    padding-left: 0px;
  }

  .main-footer .footer-widget{
    margin: 0px 0px 30px 0px !important;
  }

  .main-footer .footer-top{
    padding-bottom: 72px;
  }

  .content_block_three .content-box .experience-inner{
    position: relative;
    top: 0px;
    margin-bottom: 20px;
  }

  .working-block-one .inner-box .arrow-shape,
  .banner-style-three .owl-nav{
    display: none;
  }

  .banner-style-three .content-inner{
    margin-left: 0px;
  }

  .about-style-three .image-layer{
    right: 0px;
  }

  .service-sidebar{
    margin-right: 0px;
  }

  .image_block_one .image-box{
    margin-left: 0px;
  }

  .project-details-content .info-inner .social-links li{
    margin-bottom: 10px;
  }

  .blog-sidebar{
    margin-left: 0px;
  }

  .contact-section .content-box{
    margin-right: 0px;
  }

  .rtl .contact-section .content-box{
    margin-left: 0px;
  }

  .rtl .materials-section .list-inner{
    padding-right: 0px;
  }



}



@media only screen and (min-width: 768px){
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul,
  .main-menu .navigation > li > .megamenu{
    display:block !important;
    visibility:hidden;
    opacity:0;
  }
}



@media only screen and (max-width: 991px){
  .header-top {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }
  .header-top .top-inner {
    text-align: left;
  }
  .header-top .social-links li {
    margin-right: 8px;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-top .social-links li a {
    font-size: 13px;
  }
  .header-top ul.social-links{
    display: none;
  }
  .main-header .logo-box .logo img {
    height: 66px;
    padding: 5px 0;
    object-fit: scale-down;
  }
 /*end header*/
 
  .banner-carousel .slide-item {
    position: relative;
    padding: 95px 0px 95px 0px;
  }
  .banner-carousel .content-box h2 {
    font-size: 50px;
    line-height: 50px;
    transform: translateY(0px);
  }
  .banner-carousel .content-box p.ph4{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 15px;
  }
  .banner-carousel .content-box p {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 0;
    transform: translateY(0px);
  }
  .banner-carousel .image-box .image-shape-1 {
    top: 35px;
  }
  /*end banner*/

  .sgpSec.sec-pad {
    padding: 50px 0px 0px 0px;
  }
  .sgpSec .titleSec.pb_120 {
    padding-bottom: 70px;
  }
  .sgpSec .titleSec .sub-title {
    margin-bottom: 12px;
  }
  .sgpSec ul.infoGraphic {
    font-size: 0.5em;
    display: flex;
    justify-content: space-evenly;
  }
  .sgpSec ul.infoGraphic li.fontColor8,
  .sgpSec ul.infoGraphic li.fontColor9{
    display: none;
  }
  /** end process **/

  .benefitSec {
    padding: 50px 0 50px 0;
  }
  .benefitSec .listar-feature-item .listar-feature-item-title {
    margin: 0;
  }
  .sgpSec ul.infoGraphic li{
    margin-bottom: 7em;
  }
  .benefitSec .listar-feature-item .listar-feature-block-content-wrapper {
    margin: -32px;
    padding: 10px;
    margin-top: -30.5px;
    margin-bottom: 22px;
    }
  .benefitSec .listar-feature-item .listar-feature-item-title span {
    font-weight: 700;
    font-size: 15px;
    padding: 8px 12px;
    top:-2px;
  }
 .benefitSec .listar-feature-item .listar-feature-item-title > span span {
    width: 38px;
    height: 38px;
    left: -32px;
    line-height: 22px;
  }
  .benefitSec .listar-feature-item .listar-feature-item-inner {
    padding: 50px 10px;
  }
  .listar-feature-item .listar-feature-item-title.listar-feature-counter-added > span a {
    margin-left: 0px;
    padding: 8px 4px;
    font-size: 16px;
    left: 13px;
width: calc(100% - 62px);
  }
  /** end benefit **/

  .complianceSec .ul.nav-pills .li a {
    padding: 3px 15px;
    font-size: 15px;
  }
  /* end compliance */


  .about-section {
    padding: 50px 0px 100px 0;
  }
  .about-section .titleSec {
    text-align: center;
  }
  .about-section .content-box .btn-box{
  text-align: center;
  }
  .about-section .content-box .theme-btn span {
    padding: 12px 28px;
  }
  .theme-btn span {
    padding: 12px 28px;
  }
  .theme-btn.btn-two span {
    padding: 12px 20px;
  }
  .about-section .content-box .list-style-one.mb_35{
    margin-bottom: 22px;
  }

  /** end about **/

  .auctionListing {
    padding: 50px 0 50px 0;
  }
  .auctionListing .mb_70{
    margin-bottom: 35px;
  }
/* end auctionListing */

.industriesSec {
  padding: 20px 0;
}
.industriesSec .titleSec h2.title {
  font-size: 22px;
  line-height: 30px;
}
.industriesSec .industries-block-one {
  height: 245px;
  width: auto;
  padding: 0em 0.7em;
}
.industriesSec .industries-block-one h5 a {
  font-size: 13px;
  line-height: 29px;
} 
.industriesSec .titleSec.mb_50 {
  margin-bottom: 0px !important;
}
.industriesSec .titleSec .sub-title{
  margin-bottom: 0;
}
.industriesSec .titleSec p {
  padding-top: 0.6em;
}
.industriesSec .theme-button {
  margin-top: 1em;
}

/* end industries */

/* scrapListing */
.scrapListingd .lower-content{
  margin-top: 10px;
}


  /** solutions **/
  .solutions .feature-block.mb-5{
    margin-bottom: 4.5rem !important;
  }
  .solutions .feature-block-two .inner-box {
    padding: 0px 20px 40px 20px;
  }
  .solutions .feature-block-two .inner-box h3 a {
    font-size: 18px;
    line-height: 20px;
  }


  
  .about-section .list-style-one{
    display: flex;
    justify-content: space-between;
  }
  .sec-title h2 {
    font-size: 32px;
  }
  .image_block_one .image-box .image-1 img {
    height: 330px;
  }
  .image_block_one .image-box .image-1 {
    height:auto;
  }
  .image_block_one .image-box .image-2 {
    bottom: 99px;
    z-index: 2;
    display: none;
  }
 
  

/*** contact us ***/
.contact-section.pb_150 {
  padding-bottom: 50px;
}
.contact-section.pt_150 {
  padding-top: 50px;
}
.contact-section .sec-title {
  text-align: center;
}
.contact-section .sec-title p {
  margin-top: 0px;
}
.contact-section .content-box ul{
  display: flex;
  justify-content: space-between;
}
.contact-section,.auctionPageSec,.auctionDetails,.upcAuctionPage {
  position: relative;
  padding: 45px 0 45px 0;
}



.page-title {
  padding: 30px 0 30px;
  display: none;
}
.page-title h1 {
  font-size: 40px;
  line-height: 40px;
}
.page-title .bread-crumb li {
  font-size: 16px;
}
.main-footer .footer-top {
  padding-bottom: 15px;
  padding-top: 40px;
}

.auction .default-sidebar .category-widget .category-list li {
  font-size: 14px;
  padding: 6px 10px;
}
.h-400 {
  height: 280px;
}
.news-block-one .inner-box .image-box .post-date {
  width: inherit;
  padding: 8px 12px;
}
.news-block-one .inner-box .image-box .post-date h3 {
  font-size: 18px;
}
.nav.nav-tabs {
  margin-bottom: 1rem;
}
.scrapListingd .nav-tabs .nav-item {
  margin-bottom: 10px;
}
.nav-tabs .nav-link {
  padding: 2px 9px;
  font-size: 13px;
}


/** about us **/
  .about-style-two.pb_150{
    padding-bottom: 20px;
  }
  .about-style-two {
    padding-top: 3em;
    padding-bottom: 20px;
  }
  .about-style-two .image_block_three .image-box .image-1 img {
    width: auto;
  }
  .about-style-two .h-500 {
    height: 325px;
  }
  .image_block_three .image-box .image-2 {
    bottom: 40px;
    width: 140px;
    height: 140px;
    border: 10px solid #fff;
  }
  .image_block_three .image-box .image-shape {
    right: 0px;
    bottom: 0px;
  }
  .content_block_three .sec-title h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .sec-title .sub-title {
    margin-bottom: 4px;
  }
  .sec-title.mb_25 {
    margin-bottom: 15px;
  }

  .sec-title.mb_60{
    margin-bottom: 30px;
  }

  /** new-section **/
  .news-section.pb_70 {
    padding-bottom: 45px !important;
  }
  .news-section.pt_70 {
    padding-top: 40px !important;
  }
  .news-section .pt_60{
    padding-top: 10px;
  }
  .news-block-one .inner-box .lower-content h3 {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 8px;
  }


  /** investor logo **/
  .clients-section.pb_70 {
    padding-bottom: 50px !important;
  }
  .clients-section.pt_70 {
    padding-top: 50px !important;
  }
  .clients-section .swiper {
    margin-top:2em;
  }
.clients-section .news-block{
  text-align: center;
}

.team-details-content.mb_60{
  margin-bottom: 10px;
}
.team-details-content .content-box .designation {
  margin-bottom: 8px;
}
.team-details-content .content-box h2 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 6px;
}
.team-details-content .content-box .social-links {
  margin-bottom: 10px;
}
.team-details-content .content-box .text-box p {
  margin-bottom: 0px;
}
.team-details-content .content-box .info li {
  margin-bottom: 0px;
}
.team-details-content .text-box.mb_25{
  margin-bottom: 10px;
}
.titleSec.mb_60{
  margin-bottom: 30px !important;
}


.news-section h2{
  display: none;
}
.titleSec.mb_50 {
  margin-bottom: 20px;
}

.feature-block-two .inner-box h3 {
  font-size: 20px;
}




  .image_block_one .image-box{
    margin-top: 30px;
  }
  .service-block-one{
    padding-bottom: 30px;
  }
  .service-section{
    padding-bottom: 120px;
  }
  .image_block_two .image-box .support-box{
    left: 0px;
  }
  .materials-section .sec-title{
    padding-right: 0px;
    margin-bottom: 30px;
  }
  /* .news-block-one .inner-box{
    margin-bottom: 30px;
  } */
  .news-section{
    padding-bottom: 120px;
  }
  .subscribe-section .pattern-layer .pattern-2,
  .subscribe-section .pattern-layer .pattern-3,
  .subscribe-section .pattern-layer .pattern-4{
    display: none;
  }
  .subscribe-section{
    padding-top: 90px;
  }
  .content_block_one .content-box{
    margin-right: 0px;
  }
  .order-service .form-inner form .form-group{
    margin-bottom: 20px;
  }
  .feature-block-two{
    padding-bottom: 00px;
  }
  .image_block_three .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
    padding: 25px 50px 100px 0px;
  }

  .benefitSec .sec-title h2 {
    line-height: 30px;
  }
  .benefitSec .working-block-one {
    height:auto;
  }

  .industries .pt_60{
    padding-top: 15px;
  }
  .industries.pb_70 {
    padding-bottom: 50px !important;
  }
  .industries.pt_70 {
    padding-top: 40px !important;
  }
  .industries .service-block-one {
    padding-bottom: 0px;
  }




  
  .working-block-one .inner-box h3 {
    font-size: 16px;
    line-height: 22px;
  }
  .working-section.pb_50 {
    padding-bottom: 10px !important;
  }
  .working-section.pt_100 {
    padding-top: 50px !important;
  }
  
  .working-block-one{
    margin-bottom: 0px !important;
    height: 227px;
  }

  .working-block-one:last-child{
    margin-bottom: 0px;
  }

  .image_block_four .image-box{
    margin-left: 0px;
    margin-top: 30px;
  }

  .team-block-one.mb_75{
    margin-bottom: 35px;
  }
  .team-section{
    padding-bottom: 0px;
  }
  .team-section .pb_80 {
    padding-bottom: 50px;
  }
  .team-section.pt_60 {
    padding-top: 40px;
    padding-bottom: 25px;
  }

  .cta-style-two .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .faq-section .content-box{
    margin-right: 0px;
  }

  .rtl .header-style-two .header-lower .outer-box{
    padding-right: 30px;
  }

  .rtl .header-style-two .header-lower .menu-right-content{
    padding-left: 30px;
  }

  .rtl .header-style-two .header-lower .menu-right-content .search-box-outer{
    margin-right: 0px;
  }

  .about-style-three .image-layer{
    display: none;
  }

  .service-style-two .image-box{
    max-width: 330px;
    margin: 30px auto;
  }

  .image_block_five .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .content_block_four .content-box{
    margin-left: 0px;
  }

  .rtl .content_block_four .content-box{
    margin-right: 0px;
  }

  .news-block-two .inner-box{
    margin-bottom: 30px;
  }

  .news-style-two{
    padding-bottom: 120px;
  }

  .main-footer .footer-upper .upper-inner{
    display: block;
  }

  .main-footer .footer-upper .footer-logo{
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .service-sidebar{
    margin-bottom: 30px;
  }

  .team-details-content .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .team-details-content .content-box{
    margin-left: 0px;
  }

  .team-details .skills-inner{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .team-details .education-inner{
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .team-details .experience-inner{
    margin-left: 0px;
  }

  .pricing-block-one .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-section,
  .faq-section{
    padding-bottom: 120px;
  }

  .faq-section .content-box,
  .contact-section .content-box{
    margin-bottom: 30px;
  }

  .project-details-content .info-inner{
    display: block;
  }

  .project-details-content .info-inner .info-list li{
    margin-right: 30px;
    margin-bottom: 15px;
  }

  /*auctionDetails*/
  .auctionDetails .detailRow{
    display:grid !important;
  }
  .auctionDetails .scrapListingd {
    padding: 10px;
  }
  .auctionDetails .scrapListingd .nav-tabs .nav-item {
    padding-right: 0.4em;
  }
  .auctionDetails .content-side{
    margin-top: 1.5em;
  }
  


}


@media only screen and (max-width: 767px){

  .header-top .top-inner {
    display: grid;
    place-content: center;
  }

  .main-header .menu-right-content .btn-box{
    display: none;
  }
  .main-header .logo-box .logo img {
    height: 70px;
    padding: 3px 0;
  }
  

  .banner-carousel .slide-item{
    padding: 50px 0px 50px 0px;
  }
  .banner-section .shape,
  .about-section .pattern-layer{
    display: none;
  }
  .banner-carousel .image-box{
    margin-top: 30px;
  }
  .banner-carousel .content-box h2{
    font-size: 42px;
    line-height: 60px;
    transform: translateY(0px) !important;
    margin-bottom: 6px;
  }
  .banner-carousel .content-box p.ph4{
    font-size: 30px;
    line-height: 30px;
  }
  .banner-carousel .content-box p {
    font-size: 28.5px;
  }
  .banner-carousel .image-box .image-shape-1 {
    top: 5px;
  }

  .benefitSec .listar-feature-item-wrapper{
    margin-bottom: 9rem;
  }
  .complianceSec .complianceBox {
    height: auto;
    padding-bottom: 0px;
  }
  
  .industriesSec .industries-block{
    display: flex;
justify-content: center;
  }

  .about-section{
    padding-top: 40px;
  }

  .sec-title h2{
    font-size: 36px;
    line-height: 46px;
  }
  .about-section {
    padding: 45px 0;
  }
  .service-section{
    padding: 62px 0px 40px 0px;
  }

  /* scrapliting*/
  .scrapListingd .news-block-two{
    margin-top:2em;
  }
  .scrapListingd .news-block-two.mb-4{
    margin-bottom: 0rem !important;
  }
  .scrapDetails-tabs ul li {
    margin-right:10px;
    margin-bottom: 10px;
  }


  /** solutions **/
  .solutions .feature-block-two .inner-box {
    padding: 0px 10px 15px 10px;
  }

  .funfact-section .inner-container{
    display: block;
    text-align: center;
  }
  .sellerImgSec{
    display: none;
  }
  
  .funfact-block-one .inner-box{
    display: inline-block;
    text-align: left;
  }
  figure .solutionImg{
    text-align: center;
  }

  .funfact-block-one{
    margin-bottom: 30px;
  }

  .funfact-block-one:last-child{
    margin-bottom: 0px;
  }

  .funfact-section{
    padding: 70px 0px;
  }

  .image_block_two .image-box{
    min-height: auto;
  }

  .image_block_two .image-box .image{
    position: relative;
  }

  .chooseus-section{
    padding: 70px 0px;
  }

  .content_block_two .content-box{
    margin-left: 0px;
    padding-bottom: 0px;
  }

  .cta-section{
    padding: 65px 0px 280px 0px;
  }

  .cta-section h2 br{
    display: none;
  }

  .cta-section h2{
    font-size: 40px;
    line-height: 50px;
  }

  .materials-section .inner-container{
    padding-left: 30px;
    padding-right: 30px;
  }

  .testimonial-section{
    padding-top: 62px;
    padding-bottom: 62px;
  }

  .testimonial-section .owl-nav,
  .banner-style-two .owl-nav,
  .banner-style-two .shape{
    display: none;
  }

  .news-section{
    padding: 62px 0px 40px 0px;
  }

  .subscribe-section{
    padding: 70px 0px;
  }

  .main-footer .footer-top{
    padding-top: 50px;
  }

  .banner-style-two .banner-carousel .slide-item{
    padding: 50px 0px 50px 0px;
  }
  .banner-style-two .banner-carousel .slide-item::before {
    height: 113%;
  }

  .order-service .text-box h2{
    font-size: 36px;
    line-height: 46px;
  }

  .sec-pad,
  .faq-section{
    padding: 40px 0px 40px 0px;
  }
  .sgpSec.sec-pad {
    padding: 40px 0px 0px 0px;
  }
  .compliaceBox {
    padding: 40px 0;
    margin-bottom: 0;
  }
  .solution-section.sec-pad {
    padding: 40px 0px 0px 0;
  }
  .auctionListing {
    padding: 40px 0 40px 0;
  }
  .industriesSec {
    padding: 35px 0 10px;
  }
  .sl-benefitSec {
    padding: 40px 0;
  }
  .clients-section{
    padding: 40px 0;
  }

  .image_block_three .image-box .video-box{
    left: 0px;
  }

  .about-style-two{
    padding-bottom: 0px;
    padding-top: 2em;
  }
  .team-block-one.mb_75 {
    margin-bottom: 50px;
  }
  .image_block_three .image-box {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .working-section{
    padding: 62px 0px;
  }

  .team-section{
    padding: 0px 0px 40px 0px;
  }

  .cta-style-two .image-box .image-shape{
    display: none;
  }

  .cta-style-two .pattern-layer .pattern-2,
  .cta-style-two .pattern-layer .pattern-3,
  .cta-style-two .pattern-layer .pattern-4,
  .cta-style-two .pattern-layer .pattern-5{
    display: none;
  }

  .cta-style-two .content-box{
    margin-left: 0px;
  }

  .news-section{
    padding-bottom: 40px;
  }

  .banner-style-three .slide-item{
    padding: 200px 0px 100px 0px;
  }

  .banner-style-three .shape,
  .about-style-three .pattern-layer{
    display: none;
  }

  .banner-style-three .content-inner h2{
    font-size: 50px;
    line-height: 60px;
  }

  .about-style-three{
    padding-bottom: 70px;
  }

  .service-style-two{
    padding: 62px 0px;
  }

  .service-style-two .image-box .image-shape{
    display: none;
  }

  .skills-section{
    padding: 70px 0px;
  }

  .image_block_five .image-box .image-shape{
    display: none;
  }

  .team-section.alternat-2{
    padding-top: 62px;
  }

  .cta-style-three{
    padding: 70px 0px;
  }

  .cta-style-three .inner-box h2{
    font-size: 36px;
    line-height: 46px;
  }

  .news-style-two{
    padding-bottom: 40px;
  }

  .main-footer .footer-upper .right-column{
    display: block;
    text-align: center;
  }

  .main-footer .footer-upper .right-column h3{
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .main-footer .footer-upper .upper-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .page-title .pattern-layer{
    display: none;
  }

  .page-title{
    padding: 50px 0 50px;
  }

  .page-title h1{
    font-size: 40px;
    line-height: 20px;
  }

  .service-page-section{
    padding: 62px 0px 40px 0px;
  }

  .service-details{
    padding: 70px 0px;
  }

  .service-details-content .image-box{
    margin-bottom: 30px;
  }

  .team-details{
    padding: 70px 0px 60px 0px;
  }

  .feature-style-two.about-page{
    padding-bottom: 70px;
  }

  .feature-style-two .more-btn{
    margin-top: 0px;
  }
  .sl-benefitSec .seller p, .sl-benefitSec .dealer p {
    font-size: 14px;
  }

  .cta-style-two .content-box h2{
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  .cta-style-two .content-box .btn-box {
    justify-content: center;
  }
  .benefitSec .working-block-one {
    margin-bottom: 20px !important;
  }
  .benefitSec.pb_100 {
    padding-bottom: 40px !important;
  }
  .listar-feature-item .listar-feature-item-title.listar-feature-counter-added > span a {
    width: calc(100% - 123px);
  }
  .benefitSec .listar-feature-item .listar-feature-item-title > span span {
    left: -21px;
  }
  .benefitSec .listar-feature-item-wrapper:last-child,
  .benefitSec .listar-feature-item-wrapper:nth-last-of-type(2){
    margin-bottom: 0;
  }

  .testimonial-page-section{
    padding: 70px 0px 30px 0px;
  }

  .pricing-section{
    padding-bottom: 40px;
  }

  .error-section{
    padding: 100px 0px;
  }
  .project-style-two{
    padding: 50px 0px 40px 0px !important;
  }

  

   /** gallery **/
   .sortable-masonry .filter-tabs li {
    font-size: 16px;
    padding: 5px 10px;
  }
  .filters.centred.mb_60 {
    margin-bottom: 50px;
  }
  .project-block-two .inner-box .content-box {
    padding: 16px 18px 16px 18px;
  }
  .project-block-two .inner-box:hover .content-box {
    bottom: 40px;
  }
  .project-block-two .inner-box .content-box h3 {
    font-size: 20px;
  }

  .project-details-content .info-inner .social-links{
    margin-top: 15px;
  }

  .project-details{
    padding: 60px 0px 70px 0px;
  }

  .blog-grid,
  .sidebar-page-container{
    padding: 70px 0px;
  }

  .blog-details-content .news-block-one .inner-box .lower-content h3{
    font-size: 30px;
    line-height: 40px;
  }

  .blog-details-content .comment-box .comment.replay-comment{
    padding-left: 110px;
  }

  .blog-details-content .comment-box .comment.replay-comment .comment-thumb{
    left: 0px;
  }

  .service-style-two .left-content{
    margin-right: 0px;
  }

  .service-style-two .right-content{
    margin-left: 0px;
  }


  .contact-section,.auctionDetails,.upcAuctionPage,.scrapListingd {
    padding: 65px 0px 70px 0px;
  }

  .contact-section .form-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .order-service{
    padding-bottom: 50px;
  }

  .service-page-section .titleSec h2{
    font-size:18px;
  }
  .service-page-section {
    padding: 50px 0px 50px 0px;
  }
  .service-page-section .feature-block-two .inner-box{
    margin-bottom: 20px;
    padding: 0px 10px 10px 10px;
  }
  .service-page-section .feature-block-two .inner-box h3 {
    font-size: 17px;
    line-height: 10px;
    margin-bottom: 12px;
  }
  .service-page-section .feature-block-two .inner-box .icon-box img {
    margin-top: 10px;
  }
  .service-page-section .feature-block-two .inner-box .icon-box {
    margin-bottom: 22px;
  }
  .pagination li a {
    font-size: 15px;
    font-weight: 700;
    height: 35px;
    width: 35px;
    line-height:35px;
  }


  .content_block_three .content-box{
    margin-left: 0px;
  }
  .rtl .blog-details-content .comment-box .comment.replay-comment{
    padding-right: 110px;
  }
  .rtl .blog-details-content .comment-box .comment.replay-comment .comment-thumb{
    right: 0px;
  }
  .feature-block-one .inner-box .shape {
    left: -7px;
    background-size: 100%;
    width: 100%;
    height: 100%;
    top: 10px;
  }
  .feature-block-one .inner-box {
    padding: 22px 4px 6px 0px;
    margin-bottom: 0px;
  }
  .feature-block-one .inner-box p {
    display: none;
  }
  .feature-block-one .inner-box h3 {
    font-size: 14px;
    line-height: 20px;
    margin-top: -38px;
    padding: 35px;
    margin-bottom: 0px;
  }
  .feature-block-one .inner-box .icon-box {
    line-height: 50px;
    margin-bottom: 5px;
  }
  .team-section.alternat-2 .team-block-one .inner-box .lower-content p{
    font-size: 13px;
    font-weight: bold;
  }

}

@media only screen and (max-width: 599px){
  .banner-carousel .content-box h2 {
    font-size: 34px;
  }
  .banner-carousel .content-box p {
    font-size: 13px;
  }
  .banner-carousel .content-box p.ph4 {
    font-size: 24px;
    line-height: 24px;
  }
  .banner-carousel .content-box p {
    font-size: 23px;
  }
  .sgpSec ul.infoGraphic {
    font-size: 0.7em;
  }
  .sgpSec ul.infoGraphic li{
    max-width: 21em;
  }


  .process-popup .popup-inner {
    width: 75%;
  }

  .about-section .image_block_one .image.image-2,
  .about-section .image_block_one .image.image-3{
    display: none;
  }
  .industriesSec .industries-block-one {
    height: auto;
    width: 98%;
    padding: 0.8em 0.8em;
  }
  .benefitSec .listar-feature-item-wrapper:last-child {
    margin-bottom: 0rem;
  }
  .sl-benefitSec .titleSec{
    margin-bottom: 0;
  }

  .main-footer .footer-widget h4{
    font-size: 16px;
  }
  .page-title {
    padding: 30px 0 30px;
  }

  .image_block_one .image-box{
    padding-left: 0px;
  }
  .image_block_one .image-box .image-2{
    position: relative;
    bottom: 0px;
    margin: 30px 0px;
  }

  .image_block_one .image-box .image-3{
    position: relative;
    top: 0px;
  }

  .banner-style-three .content-inner .inner-box,
  .cta-style-three .inner-box,
  .faq-form-section .form-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-footer .footer-upper .form-inner{
    width: 100%;
  }

  .header-style-two .header-lower .menu-right-content{
    padding: 20px 0px;
    background: transparent !important;
  }

  .search-popup .upper-box{
    padding: 70px 30px;
  }

  .project-details-content .text-box{
    margin-bottom: 20px;
  }

  .blog-details-content .author-box,
  .rtl .blog-details-content .author-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .blog-details-content .author-box .author-thumb{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 20px;
  }

  .subscribe-section h2{
    font-size: 36px;
    line-height: 46px;
  }

  .sec-title h2 br{
    display: none !important;
  }

  .page-title h1{
    font-size: 32px;
    line-height: 20px;
  }

  .feature-block-one .inner-box h3 {
    font-size: 12px;
    line-height: 15px;
  }
  .feature-block-one .inner-box .shape {
    background-size: 100%;
  }
  .feature-block-one .inner-box .icon-box {
    width: 32px;
    font-size: 18px;
  }
  .team-section.alternat-2 {
    padding-top: 35px;
    padding-bottom: 20px;
  }

  .contact-section,.auctionPageSec,.auctionDetails,.upcAuctionPage,.scrapListingd {
    padding: 30px 0px 40px 0px;
  }
  .contact-section .titleSec h3{
     font-size: 18px;
  }
  .contact-section .titleSec.mb_45{
    margin-bottom: 20px;
  }
  .contact-section .content-box .info-list li h4 {
    font-size: 17px;
    line-height: 17px;
    margin-bottom: 4px;
  }
  .contact-section .content-box .info-list li p {
    font-size: 15px;
    line-height: 18px;
  }


  .sl-benefitSec .seller h3, .sl-benefitSec .dealer h3{
    font-size: 18px;
  }

  .auctionPage .listView .inner-box .lower-content .block-1,
  .auctionPage .listView .inner-box .lower-content .block-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .auctionDetails .blog-details-content .news-block-one .inner-box .lower-content h3{
    font-size: 18px;
    line-height: 18px;
  }
  .auctionDetails .scrapListingd .nav.nav-tabs{
    display: flex;
    flex-wrap: nowrap;
  }
  .auctionDetails .scrapListingd .nav-tabs .nav-link {
    line-height: 18px;
  }
  .upcAuctionPage .filterSec .dropdown{
    width:100%;
  }
  .upcAuctionPage .filterSec .dropdown button{
    width: 100%;
  }


  .scrapDetails-block2 .lower-content h4,
  .scrapDetails-block2 .lower-content2 h5,
  .scrapDetails-block2 .lower-content3 h5{
    font-size: 18px;
  }


  .sgpSec.sec-pad {
    padding: 35px 0px 0px 0px;
  }
  .compliaceBox {
    padding: 35px 0;
    margin-bottom: 0;
  }
  .solution-section.sec-pad {
    padding: 35px 0px 0px 0;
  }
  .auctionListing {
    padding: 35px 0 35px 0;
  }
  .industriesSec {
    padding: 35px 0 10px;
  }
  .sl-benefitSec {
    padding: 35px 0;
  }
  .clients-section{
    padding: 35px 0;
  }


}

@media only screen and (max-width: 576px){

  .main-footer .footer-top {
    padding-top: 25px;
    padding-bottom: 0px;
  }
  .industriesSec .titleSec{
    text-align: center !important;
  }
  .cta-style-two .content-box {
    margin-left: 0px;
    display: grid;
  }
  .industriesSec .titleSec p {
    text-align: center;
  }
  .industriesSec .titleSec h2.title {
    text-align: center;
  }
  .sellerImgSec{
    display: none;
  }
  .clients-section .swiper-button-next, .clients-section .swiper-button-prev {
    left: 40%;
  }

  .listar-feature-icon-wrapper {
    width: 92px;
    height: 92px;
    line-height: 92px;
    margin-left: -53px;
    top: -65px;
    margin-top: 0;
  }
  .listar-feature-icon-inner {
    width: 68px;
    height: 68px;
    line-height: 68px;
  }
  .listar-feature-icon-inner div {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .listar-feature-item img{
    height: 36px;
  }
  .benefitSec .listar-feature-item .listar-feature-item-inner {
    padding: 0px 10px;
  }
  .listar-feature-with-image .listar-feature-content-wrapper {
    top: -38px;
  }
  .benefitSec .listar-feature-item-wrapper {
    margin-bottom: 6rem;
  }

  .benefitSec .listar-feature-item-wrapper:nth-last-of-type(2){
    margin-bottom: 6rem;
  }
  .benefitSec .listar-feature-item-wrapper:last-child{
    margin-bottom: 0rem;
  }



  /*** contact us ****/
  .contact-section .content-box ul {
    display: grid;
  }
  .contact-section .form-inner {
    padding: 30px;
  }

  /**** gallery ****/
  .project-block-two .inner-box:hover .content-box {
    bottom: 40%;
    text-align: center;
  }

  /** solutions **/
  .solutionsSec.centred{
    padding-top: 40px !important;
    padding-bottom: 50px !important;
  }
  .solutionsSec .pagination-wrapper.centred{
    padding-top: 10px;
  }
  .solutions .feature-block.mb-5 {
    margin-bottom: 3rem !important;
  }

  /* blogPage */
  .blogPage .lower-content{
    text-align: center;
  }
  .blogPage .lower-content .link a i{
    border: 1px solid var(--theme-color);
    padding: 5px 10px;
    border-radius: 10%;
    font-size: 16px;
    transition: all 500ms ease;
  }
  .blogPage .lower-content .link a:hover i{
    background-color: var(--theme-color);
    color:#fff;
  }
}

@media only screen and (max-width: 499px){
  .main-header .outer-box {
    padding: 0px 12px;
  }
  .header-top .top-inner {
    display: grid;
    place-content: start;
  }
  .main-header .logo-box .logo img {
    height: 55px;
  }
  .header-top {
    padding: 5px 15px;
  }
  .main-header .info-list {
    display: grid;
  }
  .header-top .info-list li,
  .header-top .info-list li:last-child {
    margin-bottom: 5px !important;
    margin-right: 0px;
  }

  .main-footer .contact-widget .social-links li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
  .main-footer .widget-content .info-list.mb_20{
    margin-bottom: 10px;
  }
  .main-footer .footer-widget {
    margin: 0px 0px 15px 0px !important;
  }
  .main-footer .contact-widget .info-list li {
    font-size: 14px;
    line-height: 25px;
  }
  .main-footer .contact-widget .info-list li i {
    font-size: 16px;
  }

  .complianceSec .complianceBox {
    padding: 40px 12px;
    padding-bottom: 20px;
  }
  .complianceSec .complianceBox .innerBlock.mb_35 {
    margin-bottom: 0;
  }
  .complianceSec .complianceBox .innerBlock p{
    text-align: justify;
  }
  .complianceSec .pt_60.text-center{
    padding-top: 20px;
  }
  .complianceSec .titleSec.mb_50{
    margin-bottom: 25px;
  }

  .cta-style-two {
    position: relative;
    padding: 40px 0px 40px 0px;
  }
  .cta-style-two .content-box h2 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .theme-btn.btn-two span {
    padding: 8px 12px;
  }
  .cta-style-two .content-box .btn-box{
    justify-content: center;
  }
  .cta-style-two .content-box .btn-box .theme-btn.btn-one{
    margin-bottom: 0px;
  }

  .auctionListingBtn{
    display: flex;
    place-content: center;
    gap: 4px;
  }
  .auctionListing .theme-btn.btn-two.myBtn span {
    padding: 5px 0px;
  }



  .banner-carousel .slide-item {
    padding: 40px 0px 40px 0px;
  }
  .banner-carousel .content-box h2 {
    font-size: 30px;
    margin-bottom: 0;
  }
  .banner-carousel .content-box p.ph4{
    font-size: 21.5px;
    line-height: 21.5px;
    margin-bottom: 10px;
  }
  .banner-carousel .content-box p {
    font-size: 20.5px;
  }
  .sgpSec ul.infoGraphic {
    font-size: 0.5em;
  }
  .about-section .list-style-one {
    display: grid;
  }

  /*scrapListing*/
  .scrapListingd .default-sidebar .sidebar-widget{
    padding-left: 0px;
    padding-right: 0px;
  }
  .scrapListingd .lower-content h4{
    font-size: 18px;
  }
  .scrapDetails-tabs{
    padding: 12px;
  }
  .scrapDetails-tabs .tab-content p{
    font-size: 14px;
line-height: 21px;
  }
  .scrapDetails-tabs nav.nav-tabs{
    margin-bottom: 0.5em;
  }
  .scrapDetails-block2 .lower-content h4,
  .scrapDetails-block2 .lower-content2 h5,
  .scrapDetails-block2 .lower-content3 h5{
    font-size: 16px;
  }
  .scrapDetails-block2 .lower-content p,
  .scrapDetails-block2 .lower-content2 p,
  .scrapDetails-block2 .lower-content3 p{
    font-size: 14px;
  }
  .scrapDetails-block1 .scrap-carousel .slide-item {
    padding: 95px 0px 90px 0px;
  }
  
  /*** contact us ****/
  .sec-title.mb_45 {
    margin-bottom: 20px;
  }
  .theme-btn span {
    padding: 8px 12px;
  }
  .contact-section .form-inner {
    padding: 15px;
  }
  .signup.contact-section .titleSec.mb_45,
  .login.contact-section .titleSec.mb_45 {
    margin-bottom: 0px;
  }
  .pricing-section .tab-btns li{
    min-width: 110px;
  font-size: 17px;
  }
  .pricing-section .tab-btns::before {
    height: 30px;
    width: 60px;
    top: 5px;
  }
  .pricing-section .tab-btns li::before {
    position: absolute;
    top: 10px;
    height: 20px;
    width: 20px;
    right: -22px;
  }

  /** gallery **/
  .project-style-two {
    padding: 30px 0px 30px 0px !important;
  }
  .project-style-two .filters.mb_60{
    margin-bottom: 30px !important;
  }
  .sortable-masonry .filter-tabs li {
    font-size: 14px;
    padding: 5px 7px;
  }
  .pagination li a {
    font-size: 17px;
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
  .pagination-wrapper.pt_50{
    padding-top: 20px !important;
  }


    /*auction page*/
    .auctionPage .listingViewCol{
      display: none;
    }
    .auctionPageSec .sidebar-widget.category-widget{
      padding-left: 0px;
    padding-right: 0px;
    }
    .auctionDetails .default-sidebar .sidebar-widget{
      padding-left: 0px;
      padding-right: 0px;
    }
    .auctionDetails .lower-content .row.pb_40{
      padding-bottom: 20px;
    }
    

  .mobile-menu{
    width: 100%;
  }

  .main-header .menu-right-content{
    display: none;
  }
  .banner-carousel .image-box .image img{
    float: none;
    width: 100%;
  }
  .image_block_two .image-box .support-box{
    width: 290px;
  }
  .image_block_two .image-box .support-box h3{
    font-size: 18px;
  }
  .subscribe-section .form-inner .form-group{
    padding-right: 0px;
  }
  .subscribe-section .form-inner .form-group button[type='submit']{
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-top: 15px;
  }

  .subscribe-section .form-inner .form-group input[type='email']{
    border-radius: 10px;
  }

  .image_block_three .image-box{
    padding: 0px;
  }
  .page-title h1 {
    font-size: 25px;
    line-height: 10px;
  }
  .page-title {
    padding: 28px 0 20px;
  }
  .team-section.alternat-2 {
    padding-top: 35px;
    padding-bottom: 0;
  }
  .main-footer .links-widget .links-list li a {
    line-height: 28px;
  }
  .sec-title h2 {
    font-size: 30px;
    line-height: 20px;
  }
  .team-block-one.mb_75{
    margin-bottom: 60px;
  }
  .footer-bottom {
    padding: 15px 0px;
  }
  .main-footer .footer-top {
    padding-bottom: 0px;
  }
  .main-footer .widget-title {
    margin-bottom: 10px;
  }
  .image_block_three .image-box .image-2{
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 30px;
    display: none;
  }
  .about-style-two {
    padding-top: 2em;
    padding-bottom: 1em;
  }
  .team-details-content .content-box h2 {
    font-size: 30px;
    line-height: 25px;
  }
  .team-details-content.mb_60{
    margin-bottom: 28px;
  }
  .team-details-content .content-box .text-box p {
    margin-bottom: 10px;
  }
  .team-details-content .content-box .social-links {
    margin-bottom: 15px;
  }
  .image_block_three .image-box .image-2 img{
    border-radius: 10px;
  }
  .cta-style-three .inner-box .btn-box{
    display: block;
  }

  .cta-style-three .inner-box .btn-box .theme-btn.btn-one{
    margin-bottom: 10px;
  }

  .main-footer .footer-upper .form-inner .form-group{
    padding-right: 0px;
  }

  .main-footer .footer-upper .form-inner .form-group .theme-btn{
    position: relative;
    width: 100%;
    margin-top: 15px;
  }

  .header-style-two .header-lower .outer-box{
    padding-right: 30px;
  }

  .rtl .header-style-two .header-lower .outer-box{
    padding-left: 30px;
  }

  .default-sidebar .sidebar-widget,
  .service-details-content .content-one .inner-box .single-item,
  .rtl .service-details-content .content-one .inner-box .single-item{
    padding-left: 30px;
    padding-right: 30px;
  }
  

  .service-details-content .content-one .inner-box .single-item .icon-box{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 20px;
  }
  .service-page-section .feature-block-two .inner-box p {
    margin-bottom: 5px;
  }
  .service-page-section {
    padding: 30px 0px 30px 0px;
  }
  

  .pricing-section .tab-btn-box{
    margin-left: -10px;
    margin-right: -10px;
  }

  .project-details-content .nav-btn{
    display: block;
  }

  .blog-details-content blockquote{
    padding-left: 30px;
    padding-right: 30px;
  }

  .blog-details-content blockquote .icon-box{
    position: relative;
    top: 0px;
    margin-bottom: 20px;
    right: 0px;
    transform: translateY(0);
  }
  .main-footer .footer-widget h4 {
    font-size: 15px;
  }



}

@media only screen and (max-width: 425px){
  .menu-area .mobile-nav-toggler{
    padding: 9px !important;
  }
  .news-section .news-block{
    margin-bottom:18px;
  }
  .news-block-one .inner-box .image-box .post-date {
    bottom: -12px;
  }
  .news-block-one .inner-box .lower-content p {
    margin-bottom: 10px;
    line-height: 22px;
  }
  .news-block-one .inner-box .lower-content .link a {
    font-size: 15px;
    color: #fff;
    background-color: var(--theme-color);
    padding: 0px 10px;
    border-radius: 20%;
  }
  .news-block-one .inner-box .lower-content .link a:hover{
    color: #fff !important;
    background-color: var(--secondary-color);
  }
  .news-block-one .inner-box .image-box .image {
    height: 246px;
  }
  .banner-carousel .slide-item {
    padding: 20px 0;
  }
  .banner-carousel .image-box {
    margin-top: 0px;
  }
  .banner-carousel .content-box h2 {
    font-size: 25px;
  }
  .banner-carousel .content-box p.ph4 {
    font-size: 18px;
    line-height: 18px;
  }
  .banner-carousel .content-box p {
    font-size: 17px;
  }
  .sgpSec .titleSec h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .clients-section h3{
    font-size: 18px;
  }
}

@media only screen and (max-width: 375px){
  .header-top {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-header .logo-box .logo img {
    height: 62px;
  }
  .banner-carousel .content-box h2 {
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 5px;
  }
  .banner-carousel .content-box p.ph4 {
    font-size: 15.8px;
    line-height: 16px;
  }
  .banner-carousel .content-box p {
    font-size: 15.1px;
    line-height: 23px;
  }
  .banner-carousel .image-box .image-shape-1 {
    top: -20px;
    right: -66px;
  }
  .banner-carousel .slide-item {
    padding: 30px 0px 20px 0px;
  }
  .team-details {
    padding: 44px 0px 30px 0px;
  }
  .sgpSec.sec-pad {
    padding: 40px 0px 0px 0px;
  }
  .complianceSec {
    padding: 40px 0px 40px 0px;
  }
  .auctionListing {
    padding: 20px 0;
  }

  .auctionPage .inner-box .lower-content .block-1,
  .auctionPage .inner-box .lower-content .block-2 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .industriesSecInner{
    display: grid;
  }
  .industriesSec {
    padding: 20px 0;
  }
  .industriesSec .titleSec h2.title {
    padding-top: 0;
  }
  .industriesSec .titleSec p {
    font-size: 15px;
line-height: 25px;
    padding-top: 1em;
  }
  .titleSec .sub-title {
    margin-bottom: 0px ;
  }
  .solution-section .titleSec .sub-title {
    margin-bottom: 10px ;
  }
  .contact-section .titleSec .sub-title {
    margin-bottom: 15px;
  }
  .news-section .titleSec{
    margin-bottom: 25px !important;
  }
  .news-section .titleSec h2{
    display: none;
  }
  
  .news-block-one .inner-box .image-box .image {
    height: 190px;
  }
  .news-block-one .inner-box .lower-content p {
    margin-bottom: 9px;
    line-height: 22px;
  }
  .filters.centred.mb_60 {
    margin-bottom: 20px;
  }
  .solutions .feature-block.mb-5 {
    margin-bottom: 4rem !important;
  }
  .sgpSec ul.infoGraphic {
    font-size: 0.7em;
  }
  .news-block-one .inner-box .image-box .post-date h3 {
    font-size: 14px;
  }
  .news-block-one .inner-box .image-box .post-date {
    padding: 6px 12px;
  }
  .main-footer .links-widget .links-list li a {
    font-size: 14px;
  }

  .cta-style-two .theme-btn {
    width: 135px;
  }
  .cta-style-two .theme-btn span {
    padding: 8px 12px !important;
    font-size: 13px;
  }


  .auctionDetails .scrapListingd ul.nav.nav-tabs{
    display: grid;
  }
  .auctionDetails .scrapListingd .nav-tabs .nav-link {
    line-height: 18px;
    width: 100%;
    padding: 5px 0;
  }

  .scrapListingd .lower-content h4{
    font-size: 16px;
    margin-bottom: 4px;
  }
  .scrapListingd .lower-content p{
    font-size: 14px;
    line-height: 20px;
  }
  .scrapDetails-block1 .scrap-carousel .slide-item {
    padding: 95px 0px 70px 0px;
  }

  .sgpSec ul.infoGraphic li{
    max-width: 31em;
  }

  
  .titleSec h3{
    font-size: 16px;
  }

  .sl-benefitSec h2{
    font-size: 16px;
  }
}



















