.all-demo:hover, .buy-theme:hover {
    right: 0;
}
.buy-theme, .buy-theme:hover,
.all-demo, .all-demo:hover  {
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
}
.buy-theme, .buy-theme:hover{
    background: #00d09c;
    color:#fff;
}

.buy-theme {
    right: -137px;
    top: 50%;
}
.buy-theme i{
    font-size: 17px;
}
.all-demo, .buy-theme {
    position: fixed;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    z-index: 9999;
}
.all-demo a, .buy-theme a {
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    font-weight: 500;
}

.buy-theme i,.all-demo i {
    position: relative;
    color: #fff;
    vertical-align: middle;
    top: -1px;    
    padding: 5px 10px;
}
.all-demo span, .buy-theme span {
    padding: 0 9px 0 0;
    position: relative;
    top: 0;
    color:#fff;
    font-size: 14px;
    font-weight: 500;
}

.all-demo {
    top: 56%;
    right: -123px;
    background: #007aff;
}
.all-demo i{
    transform: rotate(95deg);
    font-size: 13px;
}